import { OrtecTheme } from '../themes/ortec.theme';
import { themes } from '../themes/themes';
import { ThemeInterface } from '../interfaces/theme.interface';

export const get = (name: string): ThemeInterface => {
  return themes.find((o) => { return o.name === name }) || OrtecTheme;
}

export const loadVariables = (theme: ThemeInterface) => {
  const element = document.documentElement;
  for (const key in theme.properties) {
    element.style.setProperty(`--${key}`, theme.properties[key]);
  }
}