import { FunctionComponent, useEffect, useState, useMemo } from "react";
import { TopSection } from "../../shared/top-section/top-section";
import dayjs, { Dayjs } from "dayjs";
import tableStyle from "./activities-table.module.scss";
import useSWR from "swr";
import { useAPI } from "../../../hooks/use-api";
import { useTranslation } from "react-i18next";
import { createActivitiesObject } from "./activities-utils";
import { FullScreenLoader } from "../../shared/full-screen-loader/full-screen-loader";
import ActivitiesHeader from "./activities-header";
import { ActivityDetails } from "../../shared/activity-details/activity-details";
import {
    ActivityInterface,
    Preference,
} from "../../../interfaces/activity.interface";
import { DbDayPart } from "../../../interfaces/day.interface";
import ActivitiesTable from "./activities-table";
import { Calendar } from "../../shared/calendar/calendar";
import { EmptyActivitiesTab } from "./empty-activities-tab/empty-activities-tab";
import { PreferencesDialog } from "./activity-preferences/preferences-dialog";
import _ from "lodash";
import { sortPreferences } from "../../../services/schedule";
import { GenericDialog } from "../../shared/generic-dialog/generic-dialog";

interface IActivitiesProps {}

interface ActivityDataResponse {
    date: string;
    activities: ActivityInterface[];
    dayParts: DbDayPart[];
}

const Activities: FunctionComponent<IActivitiesProps> = () => {
    const { t } = useTranslation();
    let { fetcher } = useAPI();

    const [date, setDate] = useState<Dayjs>(dayjs());
    const [displayedDate, setDisplayedDate] = useState<Dayjs>(dayjs());
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
    const [currentActivity, setCurrentActivity] = useState<ActivityInterface>();

    const { data: userPreferences } = useSWR<Preference[]>(
        `/api/preferences/activities`,
        fetcher
    );

    const [visibleActivityTypes, setVisibleActivityTypes] = useState<
        Preference[]
    >(userPreferences ?? []);

    useEffect(() => {
        if (!userPreferences) {
            setVisibleActivityTypes([]);
            return;
        }
        setVisibleActivityTypes(
            userPreferences?.filter((a) => a.id !== "") ?? []
        );
    }, [userPreferences]);

    const { data: teamData, isLoading } = useSWR<ActivityDataResponse>(
        `/api/schedules/activities?details=true&date=${dayjs(date).format(
            "YYYY-MM-DD"
        )}`,
        fetcher
    );

    const onDatePressed = (date: Dayjs) => {
        setDate(dayjs(date));
        setShowCalendar(false);
    };

    const dayParts = useMemo(
        () =>
            !teamData || teamData.dayParts.length === 0
                ? [{ name: "DAY", start: "00:00" }]
                : teamData.dayParts,
        [teamData]
    );

    const groupedActivities = useMemo(
        () =>
            teamData
                ? createActivitiesObject(teamData.activities, dayParts)
                : undefined,
        [dayParts, teamData]
    );

    const haveGrouppedActivities = useMemo(() => {
        if (!groupedActivities) {
            return false;
        }

        if (_.isEmpty(Object.keys(groupedActivities))) {
            return false;
        }

        return true;
    }, [groupedActivities]);

    const activitiesToBeDisplayed = useMemo(() => {
        if (!groupedActivities) return {};
        const activeActivityTypes = visibleActivityTypes.filter(
            (a) => a.isActive && Object.keys(groupedActivities).includes(a.id)
        );
        let result = {};
        for (let activityType of activeActivityTypes) {
            result = {
                ...result,
                [activityType.id]: groupedActivities[activityType.id],
            };
        }

        return result;
    }, [groupedActivities, visibleActivityTypes]);

    const showEmptyTab = useMemo(
        () => Object.keys(activitiesToBeDisplayed).length <= 0,
        [activitiesToBeDisplayed]
    );

    useEffect(() => {
        if (!teamData) {
            return;
        }

        setDisplayedDate(dayjs(teamData.date));
    }, [teamData]);

    useEffect(() => {
        document.title = t("activities.title");
    }, [t]);

    const handleDialogToggle = () => {
        setIsDetailsOpen(!isDetailsOpen);
    };

    const handleCalendarClick = () => {
        setShowCalendar(!showCalendar);
    };

    const { api } = useAPI();

    const updatePreferences = async () => {
        const url = `/api/preferences/activities`;

        const data = { preferences: visibleActivityTypes };
        try {
            await api.put(url, data);
        } catch (e) {
            console.log(e);
        }
    };

    const handlePreferencesClick = async () => {
        //If dialog is closing save the preferences
        if (isPreferencesOpen) {
            setVisibleActivityTypes(sortPreferences(visibleActivityTypes));
            await updatePreferences();
        }
        setIsPreferencesOpen(!isPreferencesOpen);
    };

    const handleClickCard = (cardRootId: number) => {
        if (!teamData) return;

        const newCurrentActivity = teamData.activities.find(
            (activity: ActivityInterface) => activity.id === cardRootId
        );

        if (!newCurrentActivity) return;
        const a = {
            ...newCurrentActivity,
            activities: teamData.activities.filter(
                (activity: ActivityInterface) => activity.rootId === cardRootId
            ),
        };

        setCurrentActivity(a);
        handleDialogToggle();
    };

    return (
        <>
            <TopSection>
                <ActivitiesHeader
                    date={displayedDate}
                    handleDateChange={onDatePressed}
                    handleCalendarChange={setDisplayedDate}
                    isCalendarOpen={showCalendar}
                    handleCalendarClick={handleCalendarClick}
                    onPreferencesClick={handlePreferencesClick}
                />
            </TopSection>
            {showCalendar && (
                <Calendar
                    currentDate={displayedDate}
                    onDatePressed={onDatePressed}
                />
            )}
            <div className={tableStyle.teamTableContainer}>
                {groupedActivities && !isLoading && dayParts ? (
                    <>
                        {isPreferencesOpen && (
                            <GenericDialog
                                isOpen={isPreferencesOpen}
                                handleClose={handlePreferencesClick}
                                isResourcePreferences={true}
                            >
                                <div className={tableStyle.preferencesContainer}>
                                    <PreferencesDialog
                                        handlePreferencesChange={
                                            setVisibleActivityTypes
                                        }
                                        visiblePreferences={
                                            visibleActivityTypes
                                        }
                                        isResourcePreferences={false}
                                    />
                                </div>
                            </GenericDialog>
                        )}
                        {showEmptyTab ? (
                            <EmptyActivitiesTab
                                emptyLabel={
                                    haveGrouppedActivities
                                        ? t("activities.emptyTabText")
                                        : t("activities.noActivitiesToday")
                                }
                                openPreferences={handlePreferencesClick}
                                tab="activities"
                            />
                        ) : (
                            <>
                                <ActivitiesTable
                                    groupedActivities={activitiesToBeDisplayed}
                                    dayParts={dayParts}
                                    handleClickCard={handleClickCard}
                                />
                                {isDetailsOpen ? (
                                    currentActivity ? (
                                        <ActivityDetails
                                            activity={currentActivity}
                                            handleDialogToggle={
                                                handleDialogToggle
                                            }
                                            isDetailsOpen={isDetailsOpen}
                                        />
                                    ) : (
                                        <FullScreenLoader />
                                    )
                                ) : null}
                            </>
                        )}
                    </>
                ) : (
                    <FullScreenLoader />
                )}
            </div>
        </>
    );
};

export default Activities;
