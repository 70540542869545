import dayjs from "dayjs";

export interface ICalendarEvent {
  startTime: string;
  endTime: string;
  isAllDay: boolean;
  type: string;
  cssClasses?: string[];
  label?: string;
  activityType?: any;
}

interface ICalendarEventProps extends ICalendarEvent {}

export function CalendarEvent(props: ICalendarEventProps) {

  const calculateTimePercentage = (date: Date | string, begin?: Date | string): number => {
    if (typeof date === 'string') date = dayjs(date).toDate();
    if (begin && typeof begin === 'string') begin = dayjs(begin).toDate();

    const startOfDay = new Date(date.getTime());
    startOfDay.setHours(0, 0, 0, 0);

    let percentage = ((date.getTime() - startOfDay.getTime()) / (1000 * 60 * 60 * 24) * 100);

    if (begin) {
      percentage = 100 - percentage;
      if (!dayjs(date).isSame(begin, 'day')) {
        percentage = percentage === 100 ? 0 : -(100 - percentage);
      }
    }

    return percentage;
  }

  let cssClasses = `calendar-event event-${props.type}`;
  if (props.isAllDay) cssClasses += ` all-day`;
  if (props.cssClasses) cssClasses += ` ${props.cssClasses.join(' ')}`

  const style = {
    left: props.isAllDay ? 'auto' : `${calculateTimePercentage(props.startTime)}%`,
    right: props.isAllDay ? 'auto' : `${calculateTimePercentage(props.endTime, props.startTime)}%`,
    backgroundColor: '#' + props.activityType.backColor
  }

  return (
    <div className={cssClasses} style={style}>
      {props.label ? <span className="label">{props.label}</span> : null}
    </div>
  )
}