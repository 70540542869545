import { FunctionComponent, useState } from "react";

import { Dropdown, DropdownOption } from "@o4c/plugin-components";

import styles from "./filters-dialog.module.scss";
import { useTranslation } from "react-i18next";
import { PreferenceCard } from "../preference-card/preference-card";
import { Preference } from "../../../interfaces/activity.interface";
import {
    OMRPResourceProperty,
    ResourceFilter,
    ResourcePropertyWithValues,
} from "../../../interfaces/resource.interface";
import _ from "lodash";

interface IFiltersDialogProps {
    filters?: ResourceFilter;
    propertiesObject: ResourcePropertyWithValues[];
    handleFiltersChange: (f: ResourceFilter) => void;
}
export const FiltersDialog: FunctionComponent<IFiltersDialogProps> = (
    props: IFiltersDialogProps
) => {
    const { propertiesObject, filters, handleFiltersChange } = props;

    const { t } = useTranslation();

    const [selectedProperty, setSelectedProperty] = useState(
        filters?.selectedProperty ?? propertiesObject[0] ?? null
    );
    const [actives, setActives] = useState<Preference[]>(
        filters?.actives ??
            selectedProperty.values.map((p: string) => ({
                id: p,  
                isActive: false,
            }))
    );

    //? Should we move this in a util file since it's used on preferences as well?
    //? Check what will occur with the final object that we will manage here
    const handleSelectClick = () => {
        const activeStatusFound = actives.some((a) => a.isActive);
        const _actives = actives.map((a) => ({
            ...a,
            isActive: !activeStatusFound,
        }));
        setActives(_actives);
        handleFiltersChange({ selectedProperty, actives: _actives });
    };

    const handlePreferenceClick = (isActive: boolean, text: string) => {
        //On switch click on the preference change the is active status.
        const _actives = actives.map((a) => {
            if (a.id === text) {
                return { ...a, isActive: !isActive };
            }
            return a;
        });
        setActives(_actives);
        handleFiltersChange({ selectedProperty, actives: _actives });
    };

    const handlePropertyChange = (v: string) => {
        if (_.isEmpty(propertiesObject)) {
            return;
        }

        const property = propertiesObject.find(
            (p: ResourcePropertyWithValues) =>
                p.resourcePropertyId.toString() === v
        );
        if (!property) {
            setActives([]);
            return;
        }

        setSelectedProperty(property);
        let _actives = actives;
        if (v !== selectedProperty.resourcePropertyId.toString()) {
            _actives = property.values.map((p: string) => ({
                id: p,
                isActive: false,
            }));

            setActives(_actives);
        }

        handleFiltersChange({ selectedProperty: property, actives: _actives });
    };

    return (
        <>
            <FilterPropertySelect
                selectedProperty={selectedProperty}
                handleChange={handlePropertyChange}
                options={propertiesObject ?? []}
            />
            {selectedProperty && (
                <>
                    <div className={styles.listLabel}>
                        <div className={styles.listLabelText}>{`${t(
                            "resources.filters"
                        )}: ${selectedProperty.text ?? ""}`}</div>
                        <div
                            className={styles.selectAll}
                            onClick={handleSelectClick}
                        >
                            {actives.map((a) => a.isActive).includes(true)
                                ? `${t("activities.deselectAll")}`
                                : `${t("activities.selectAll")}`}
                        </div>
                    </div>
                    {actives.map((o: Preference) => (
                        <PreferenceCard
                            key={o.id}
                            text={o.id}
                            canReorder={false}
                            isActive={o.isActive}
                            handleChange={handlePreferenceClick}
                        />
                    ))}
                </>
            )}
        </>
    );
};

interface IFilterPropertySelectProps {
    selectedProperty: OMRPResourceProperty;
    handleChange: (v: string) => void;
    options: ResourcePropertyWithValues[];
}
const FilterPropertySelect: FunctionComponent<IFilterPropertySelectProps> = (
    props: IFilterPropertySelectProps
) => {
    const { selectedProperty, handleChange, options } = props;
    const { t } = useTranslation();

    return (
        <>
            <Dropdown
                label={`${t("resources.selectProperty")}`}
                name="resource-properties"
                placeholder=""
                value={selectedProperty.resourcePropertyId.toString()}
                onChange={(v) => handleChange(v)}
            >
                {options.map((o: ResourcePropertyWithValues) => (
                    <DropdownOption
                        key={o.resourcePropertyId}
                        label={o.text}
                        value={o.resourcePropertyId.toString()}
                    />
                ))}
            </Dropdown>
        </>
    );
};
