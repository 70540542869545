import dayjs from "dayjs";
import { Link } from "react-router-dom";
import NoShiftCard from "../../../shared/no-shift-card/no-shift-card";
import { ShiftCard } from "../shift-card/shift-card";
import { useTranslation } from "react-i18next";
import { DayInterface } from "../../../../interfaces/day.interface";
import { ActivityInterface } from "../../../../interfaces/activity.interface";
import { HolidayInterface } from "../../../../interfaces/holiday.interface";
import { Pill } from "@o4c/plugin-components";

interface ScheduleTimelineItemProps {
  day: DayInterface;
  holidays: HolidayInterface[];
}

export function ScheduleTimelineItem(props: ScheduleTimelineItemProps) {
  const { t } = useTranslation()
  const date = dayjs(props.day.date);
  const isToday = dayjs().isSame(date, 'day');

  return (
    <Link to={`/schedule/${date.format('YYYY-MM-DD')}/details`} className={`item ${isToday ? 'accent' : props.day.shiftCount > 0 ? 'primary' : ''}`}>
      <p className="title"><span>{dayjs(props.day.date).format(t('dateFormatting.day'))}</span><i className="icon-arrow-right trailing"></i></p>
      {props.holidays.map((holiday: HolidayInterface, index: number) => <Pill key={index}>{holiday.text}</Pill>)}
      {props.day.shiftCount === 0 ? <NoShiftCard /> : null}
      {props.day.shifts.map((shift: ActivityInterface, ix: number) => <ShiftCard shift={shift} key={ix} />)}
    </Link>
  )
}