import dayjs, { Dayjs } from "dayjs";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";
import { useAPI } from "../../../hooks/use-api";
import { TopSection } from "../../shared/top-section/top-section";
import "./team-schedule.scss";
import { FullScreenLoader } from "../../shared/full-screen-loader/full-screen-loader";
import { useAuth } from "../../../auth/use-auth";
import {
    createTeamSchedule,
    createUserSchedule,
    sortPreferences,
} from "../../../services/schedule";
import { DayTile } from "../../shared/day-tile/day-tile";
import {
    OMRPOrganizationUnit,
    OMRPResource,
    OMRPResourceProperty,
    ResourceFilter,
    ResourceInterface,
    ResourcePropertyWithValues,
} from "../../../interfaces/resource.interface";
import { Calendar } from "../../shared/calendar/calendar";
import { Button, Icon } from "@o4c/plugin-components";
import { DayPart, TeamScheduleDate } from "../../../interfaces/day.interface";
import {
    ActivityInterface,
    Preference,
} from "../../../interfaces/activity.interface";
import { ActivityDetails } from "../../shared/activity-details/activity-details";
import _ from "lodash";
import { EmptyActivitiesTab } from "../activities/empty-activities-tab/empty-activities-tab";
import { ResourceTypeAccordion } from "../../shared/resource-type-accordion/resource-type-accordion";
import { GenericDialog } from "../../shared/generic-dialog/generic-dialog";
import { TeamScheduleFilters } from "../../shared/team-schedule-filters/team-schedule-filters";

type TeamScheduleT = {
    resources: ResourceInterface[];
    dates: TeamScheduleDate[];
} | null;

export interface ITeamScheduleData {
    data: { activities: ActivityInterface[] };
    date: string;
    dayParts: DayPart[];
    resources: OMRPResource[];
    organizationUnits: any[];
}

export const OMRP_DISPLAY_NAME_PROPERTY_ID = 9999;
export const OMRP_ORG_UNIT_PROPERTY_ID = 9997;

const TeamSchedule = () => {
    const { t, i18n } = useTranslation();
    let params: any = useParams();

    const { user } = useAuth();
    let { fetcher } = useAPI();

    const history = useHistory();

    const [date, setDate] = useState(
        params.date ? dayjs(params.date) : dayjs()
    );
    const [activeWeek, setActiveWeek] = useState<Dayjs>(dayjs());
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [currentActivity, setCurrentActivity] = useState<ActivityInterface>();
    const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
    const [isPreferencesOpen, setIsPreferencesOpen] = useState<boolean>(false);
    const [filters, setFilters] = useState<ResourceFilter>();

    const { data: resourcePreferenes, isLoading } = useSWR<Preference[]>(
        `/api/preferences/team`,
        fetcher
    );

    const [visibleResourceTypes, setVisibleResourceTypes] = useState<
        Preference[]
    >(resourcePreferenes ?? []);

    const { data: teamScheduleData } = useSWR<ITeamScheduleData>(
        `/api/schedules/team?date=${date.format("YYYY-MM-DD")}`,
        fetcher
    );

    const { data: filtersAndProperties } = useSWR(
        `/api/preferences/filters`,
        fetcher
    );

    const resourceProperties = useMemo(() => {
        if (!filtersAndProperties) {
            return [];
        }

        const { resourceProperties, filters } = filtersAndProperties;
        if (filters) {
            setFilters(filters);
        }

        const lang = i18n.language.split("-")[0] ?? "en";
        if (!resourceProperties[lang]) {
            return resourceProperties["en"];
        }

        return resourceProperties[lang];
    }, [filtersAndProperties, i18n.language]);

    const [teamSchedule, userSchedule] = useMemo((): [
        TeamScheduleT,
        ActivityInterface[] | null
    ] => {
        if (!teamScheduleData) {
            setActiveWeek(dayjs);
            return [null, null];
        }
        const { data, date } = teamScheduleData;
        const { activities } = data;
        setActiveWeek(dayjs(date));
        return [
            createTeamSchedule(teamScheduleData, user),
            createUserSchedule(activities, user),
        ];
    }, [teamScheduleData, user]);

    //Filtering this to only have existing values in filters
    const omrpResourcesWithActivities = useMemo(() => {
        if (!teamScheduleData?.resources || !teamSchedule?.resources) {
            return [];
        }
        const resourceIds = teamSchedule.resources.map((r) => r.resourceId);
        return teamScheduleData.resources.filter((r) =>
            resourceIds.includes(r.id)
        );
    }, [teamSchedule?.resources, teamScheduleData?.resources]);

    const propertiesObject = useMemo((): ResourcePropertyWithValues[] => {
        if (_.isEmpty(resourceProperties) || !teamScheduleData) {
            return [];
        }

        const propertiesWithValues = createPropertiesObject(
            resourceProperties,
            omrpResourcesWithActivities,
            teamScheduleData?.organizationUnits
        );
        return propertiesWithValues.filter((p) => !_.isEmpty(p.values));
    }, [resourceProperties, teamScheduleData, omrpResourcesWithActivities]);

    useEffect(() => {
        setVisibleResourceTypes(
            resourcePreferenes?.filter((a) => a.id !== "") ?? []
        );
    }, [resourcePreferenes]);

    const activeResourceTypes = useMemo(() => {
        return visibleResourceTypes.filter((r) => r.isActive);
    }, [visibleResourceTypes]);

    const isEmptyTab = useMemo(() => {
        return _.isEmpty(activeResourceTypes);
    }, [activeResourceTypes]);

    useEffect(() => {
        document.title = t("teamSchedule.title");
    }, [t]);

    const onDatePressed = (date: Dayjs) => {
        //Add one day cause first day of the week is Sunday
        history.push(
            `/team-schedule/${dayjs(date)
                .startOf("week")
                .add(1, "day")
                .format("YYYY-MM-DD")}`
        );
        setDate(dayjs(date));
        setShowCalendar(false);
    };

    const { api } = useAPI();

    const updatePreferences = async () => {
        const url = `/api/preferences/team`;

        const data = { preferences: visibleResourceTypes };
        try {
            await api.put(url, data);
        } catch (e) {
            console.error(e);
        }
    };

    const updateFilters = async () => {
        const url = `/api/preferences/filters`;

        const data = { ...filters };
        try {
            await api.put(url, data);
        } catch (e) {
            console.error(e);
        }
    };

    const handlePreferencesOpen = () => {
        setIsPreferencesOpen(true);
    };

    const handlePreferencesClose = async () => {
        setVisibleResourceTypes(sortPreferences(visibleResourceTypes));
        await updatePreferences();
        await updateFilters();
        setIsPreferencesOpen(false);
        return;
    };

    const handleDialogToggle = () => {
        setIsDetailsOpen(!isDetailsOpen);
    };

    const handleCalendarClick = () => {
        setShowCalendar(!showCalendar);
    };

    const handleClickTile = (activityParam: ActivityInterface) => {
        setCurrentActivity({ ...activityParam, activities: [] });
        handleDialogToggle();
    };

    return (
        <>
            <TopSection>
                <div className="screen-header">
                    <div className="calendar-button">
                        <Button
                            display="inline"
                            size="small"
                            type="outline"
                            onClick={handleCalendarClick}
                        >
                            {!showCalendar ? (
                                <Icon name="schedule" />
                            ) : (
                                <Icon name="close" />
                            )}
                        </Button>
                    </div>
                    <TeamScheduleNavigation
                        date={activeWeek}
                        handleDateChange={onDatePressed}
                        handleCalendarChange={setActiveWeek}
                        isCalendarOpen={showCalendar}
                    />
                    <div className="settings-button">
                        <Button
                            display="inline"
                            size="small"
                            type="outline"
                            onClick={handlePreferencesOpen}
                        >
                            <Icon name="filter" size="small" />
                        </Button>
                    </div>
                </div>
                <div
                    className={`calendar-container ${
                        showCalendar ? "show" : "hide"
                    }`}
                >
                    <Calendar
                        onDatePressed={onDatePressed}
                        currentDate={activeWeek}
                    />
                </div>
            </TopSection>
            {teamSchedule && userSchedule && !isLoading ? (
                <div className="team-table-container">
                    <table className="team-table">
                        {isEmptyTab ||
                        !hasActivitiesToDisplay(
                            activeResourceTypes,
                            teamSchedule
                        ) ? (
                            <EmptyActivitiesTab
                                emptyLabel={
                                    isEmptyTab
                                        ? t("resources.emptyTabText")
                                        : t("resources.noActivitiesToday")
                                }
                                openPreferences={handlePreferencesOpen}
                                tab="resources"
                            />
                        ) : (
                            <TeamScheduleTableHeader
                                teamSchedule={teamSchedule}
                                userSchedule={userSchedule}
                                isVisible={!showCalendar}
                            />
                        )}
                        <tbody>
                            {activeResourceTypes.map(
                                (resourceType: Preference) => {
                                    const resourcesForType =
                                        getResourcesForType(
                                            teamSchedule,
                                            resourceType
                                        );

                                    return (
                                        <ResourceTypeAccordion
                                            key={resourceType.OMRPId}
                                            resourceType={resourceType}
                                            resourcesWithActivities={
                                                resourcesForType
                                            }
                                            resources={
                                                omrpResourcesWithActivities ??
                                                []
                                            }
                                            organizationUnits={teamScheduleData?.organizationUnits ?? []}
                                            isFiltersDialogOpen={
                                                isPreferencesOpen
                                            }
                                            handleClickTile={handleClickTile}
                                            dayParts={
                                                teamScheduleData?.dayParts ?? []
                                            }
                                            dates={teamSchedule?.dates}
                                            filters={filters}
                                        />
                                    );
                                }
                            )}
                        </tbody>
                        {isPreferencesOpen && (
                            <GenericDialog
                                isOpen={isPreferencesOpen}
                                handleClose={handlePreferencesClose}
                                isResourcePreferences={true}
                            >
                                <TeamScheduleFilters
                                    handlePreferencesChange={
                                        setVisibleResourceTypes
                                    }
                                    visiblePreferences={visibleResourceTypes}
                                    propertiesObject={propertiesObject}
                                    filters={filters}
                                    handleFiltersChange={setFilters}
                                />
                            </GenericDialog>
                        )}
                        {isDetailsOpen ? (
                            currentActivity ? (
                                <ActivityDetails
                                    activity={currentActivity}
                                    handleDialogToggle={handleDialogToggle}
                                    isDetailsOpen={isDetailsOpen}
                                />
                            ) : (
                                <FullScreenLoader />
                            )
                        ) : null}
                    </table>
                </div>
            ) : (
                <FullScreenLoader />
            )}
        </>
    );
};

interface ITeamScheduleNavigationProps {
    date: Dayjs;
    handleDateChange: (date: Dayjs) => void;
    handleCalendarChange: (date: Dayjs) => void;
    isCalendarOpen: boolean;
}

export const TeamScheduleNavigation: FunctionComponent<
    ITeamScheduleNavigationProps
> = (props: ITeamScheduleNavigationProps) => {
    const { date, handleDateChange, handleCalendarChange, isCalendarOpen } =
        props;

    //Add one day cause first day of the week is Sunday
    const _date = isCalendarOpen
        ? `${date.startOf("month").format("MMMM YYYY")}`
        : `Week ${date.startOf("week").add(1, "day").format("W, MMMM YYYY")}`;

    const handleLeftArrowClick = () => {
        isCalendarOpen
            ? handleCalendarChange(date.subtract(1, "month"))
            : handleDateChange(date.clone().subtract(1, "week"));
    };

    const handleRightArrowClick = () => {
        isCalendarOpen
            ? handleCalendarChange(date.add(1, "month"))
            : handleDateChange(date.clone().add(1, "week"));
    };

    return (
        <div className="team-nav">
            <div className="team-nav-arrow-button">
                <Icon name="arrow-left" onClick={handleLeftArrowClick} />
            </div>
            <div className="team-nav-date">{_date}</div>
            <div className="team-nav-arrow-button">
                <Icon name="arrow-right" onClick={handleRightArrowClick} />
            </div>
        </div>
    );
};

interface ITeamScheduleTableHeaderProps {
    teamSchedule: TeamScheduleT;
    userSchedule: ActivityInterface[];
    isVisible: boolean;
}
const TeamScheduleTableHeader: FunctionComponent<
    ITeamScheduleTableHeaderProps
> = (props: ITeamScheduleTableHeaderProps) => {
    const { teamSchedule, userSchedule, isVisible } = props;

    if (!isVisible) {
        return null;
    }

    if (!teamSchedule) {
        return null;
    }

    return (
        <thead>
            <td className="thead-container">
                <tr className="dates">
                    <th className="margin"></th>
                    {teamSchedule.dates.map(
                        (date: TeamScheduleDate, index: number) => (
                            <DayTile
                                key={"dayTile" + index}
                                date={date}
                                userSchedule={userSchedule}
                            />
                        )
                    )}
                </tr>
            </td>
        </thead>
    );
};

const getResourcesForType = (
    teamSchedule: TeamScheduleT,
    resourceType: Preference
): ResourceInterface[] => {
    if (!teamSchedule) {
        return [];
    }
    return teamSchedule.resources.filter((r: ResourceInterface) => {
        if (!resourceType?.OMRPId) {
            return false;
        }
        return r.resourceTypeIds.includes(resourceType?.OMRPId);
    });
};

const hasActivitiesToDisplay = (
    actives: Preference[],
    teamSchedule: TeamScheduleT
): boolean => {
    if (_.isEmpty(actives)) {
        return false;
    }

    if (!teamSchedule) {
        return false;
    }

    for (let a of actives) {
        if (!_.isEmpty(getResourcesForType(teamSchedule, a))) {
            return true;
        }
    }

    return true;
};

const createPropertiesObject = (
    properties: OMRPResourceProperty[],
    resources: OMRPResource[],
    organizationUnits: OMRPOrganizationUnit[]
) => {
    let result: ResourcePropertyWithValues[] = [];
    properties.forEach((p) => {
        const id = p.resourcePropertyId;
        const values = findPropertyValues(id, resources, organizationUnits);
        result.push({
            ...p,
            values,
        });
    });

    return result;
};

const findPropertyValues = (
    id: number,
    resources: OMRPResource[],
    organizationUnits: OMRPOrganizationUnit[]
) => {
    if (id === OMRP_DISPLAY_NAME_PROPERTY_ID) {
        return resources.map((r) => r.displayName);
    }

    if(id === OMRP_ORG_UNIT_PROPERTY_ID) {
        return organizationUnits.map(ou => ou.displayName);
    }

    const properties = _.flatten(
        _.map(resources, (r) => {
            return r.resourceProperties;
        })
    );

    const filtersForId = _.filter(
        properties,
        (p) => p.resourcePropertyId === id
    ).map((p) => p.value);
    return Array.from(new Set(filtersForId));
};

export default TeamSchedule;
