import { ICalendarDay } from "../calendar";
import { CalendarDay } from "../calendar-day/calendar-day";

interface ICalendarWeekProps {
  week: ICalendarDay[];
  onDatePressed?: Function;
}

export function CalendarWeek(props: ICalendarWeekProps) {
  return (
    <div className="calendar-week">
      {props.week ? props.week.map((day: ICalendarDay, i: number) => (<CalendarDay onDatePressed={props.onDatePressed} day={day} key={i} />)) : null}
    </div>
  )
}