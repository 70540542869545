import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import { ShiftDetailCard } from "./shift-detail-card/shift-detail-card";
import { useAPI } from "../../../hooks/use-api";
import { TopSection } from "../../shared/top-section/top-section";
import './schedule-details.scss';
import NoShiftCard from "../../shared/no-shift-card/no-shift-card";
import { FullScreenLoader } from "../../shared/full-screen-loader/full-screen-loader";
import { ShiftDetailCalendar } from "./shift-detail-calendar/shift-detail-calendar";
import { useTranslation } from "react-i18next";
import { HolidayInterface } from "../../../interfaces/holiday.interface";
import { Pill } from "@o4c/plugin-components"
import { createDaySchedule } from "../../../services/schedule";

export function ScheduleDetails() {
    const { t } = useTranslation()
    let params: any = useParams();
    let { fetcher } = useAPI();

    const [date, setDate] = useState<Dayjs>(params.date ? dayjs(params.date) : dayjs())

    const { data: scheduleData, error: scheduleError } = useSWR<any>(`/api/schedules/day?date=${date.format('YYYY-MM-DD')}`, fetcher)
    const { data: holidayData, error: holidayError } = useSWR<any>(`/api/holidays/?year=${date.clone().startOf('month').format("YYYY")}`, fetcher)

    useEffect(() => {
        const date = dayjs(params.date)
        setDate(date);
    }, [params]);

    if (scheduleError || holidayError) return <div>Failed to load!</div>;

    return (
        <div>
            <TopSection>
                <div className="screen-header">
                    <div>
                        <Link className="action" to={`/schedule/${date.format('YYYY-MM-DD')}`}><span><i className="icon-schedule" /></span></Link>
                        <div>{date.format(t('dateFormatting.day'))}</div>
                        <button>&nbsp;</button>
                    </div>
                </div>
                <ShiftDetailCalendar date={date} />
            </TopSection>
            {!scheduleData || !holidayData ? <FullScreenLoader /> :
                <div className="container">
                    {
                        holidayData.holidays
                            .filter((holiday: HolidayInterface) => holiday.holidayDate === date.clone().format('YYYY-MM-DD'))
                            .map((holiday: HolidayInterface, index: number) =>
                                    <Pill key={index}>{holiday.text}</Pill>
                            )
                    }
                    {
                        scheduleData.activities.length === 0 ? <NoShiftCard /> :
                            createDaySchedule(scheduleData).shifts.map((shift: any, i: any) => (<ShiftDetailCard key={i} date={date} shift={shift} />))
                    }
                </div>
            }
        </div>
    )
}