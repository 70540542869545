import { FunctionComponent } from "react";
import { IGroupedActivities, SourceObject } from "./activities-utils";
import { DbDayPart } from "../../../interfaces/day.interface";
import SourceCard from "./source-card";
import tableStyle from "./activities-table.module.scss";

interface IActivitiesTableProps {
    groupedActivities: IGroupedActivities;
    dayParts: DbDayPart[];
    handleClickCard: (cardRootId: number) => void;
}

const ActivitiesTable: FunctionComponent<IActivitiesTableProps> = ({
    groupedActivities,
    dayParts,
    handleClickCard,
}) => {
    return (
        <table className={tableStyle.teamTable}>
            {dayParts.length > 1 ? (
                <thead className={tableStyle.dayparts}>
                    <tr>
                        {dayParts?.map((dayPart: DbDayPart, index: number) => (
                            <th
                                className={tableStyle.th}
                                key={dayPart.name + index}
                            >
                                <div className={tableStyle.daypartText}>
                                    {" "}
                                    {dayPart.name.substring(0, 2).toUpperCase()}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
            ) : null}
            {Object.keys(groupedActivities).map(
                (activityTitle: string, activityIndex: number) => (
                    <tbody key={activityIndex} className={tableStyle.tbody}>
                        <tr>
                            <td
                                className={tableStyle.name}
                                style={{
                                    background: `#${groupedActivities[activityTitle].backColor}`,
                                    color: `#${groupedActivities[activityTitle].textColor}`,
                                }}
                                colSpan={1}
                            >
                                {activityTitle}
                            </td>
                            {dayParts.length > 1 && (
                                <td
                                    className={tableStyle.nameCell}
                                    style={{
                                        background: `#${groupedActivities[activityTitle].backColor}`,
                                        color: `#${groupedActivities[activityTitle].textColor}`,
                                    }}
                                    colSpan={dayParts.length - 1}
                                ></td>
                            )}
                        </tr>
                        <tr>
                            {dayParts?.map(
                                (dayPart: DbDayPart, dayPartIndex: number) => {
                                    const sources = (
                                        groupedActivities[activityTitle][
                                            dayPart.name
                                        ] as SourceObject
                                    ).sources;
                                    return (
                                        <td
                                            key={dayPartIndex}
                                            className={tableStyle.daypartCell}
                                        >
                                            <div
                                                className={
                                                    tableStyle.sourcesContainer
                                                }
                                                key={
                                                    dayPart.name +
                                                    dayPart.start +
                                                    dayPartIndex
                                                }
                                            >
                                                <SourceCard
                                                    handleClickCard={
                                                        handleClickCard
                                                    }
                                                    sources={sources}
                                                />
                                            </div>
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                    </tbody>
                )
            )}
        </table>
    );
};

export default ActivitiesTable;
