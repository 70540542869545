import { Dialog, DialogContent, Slide } from "@material-ui/core";
import dayjs from "dayjs";
import NoShiftCard from "../no-shift-card/no-shift-card";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import React, { useMemo } from "react";
import { Icon, Spinner } from "@o4c/plugin-components";
import { ActivityInterface } from "../../../interfaces/activity.interface";
import { useAPI } from "../../../hooks/use-api";
import useSWR from "swr";
import { ShiftDetailCardContent } from "../../views/schedule-details/shift-detail-card/shift-detail-card";
import _ from "lodash";
import { createDetailsObject } from "../../../services/schedule";

interface IActivityDetailsProps {
    isDetailsOpen: boolean;
    handleDialogToggle: () => void;
    activity: ActivityInterface;
}

export const ActivityDetails = (props: IActivityDetailsProps) => {
    const { activity, isDetailsOpen, handleDialogToggle } = props;

    let { fetcher } = useAPI();
    const {
        data: activityData,
        error: teamScheduleError,
        isLoading,
    } = useSWR(`/api/schedules/details?activity=${activity.id}`, fetcher);

    const detailsObject = useMemo(() => {
        if (!activityData) {
            return activity;
        }

        return createDetailsObject(activityData);
    }, [activity, activityData]);

    return (
        <Dialog
            open={isDetailsOpen}
            TransitionComponent={Transition}
            maxWidth="md"
            fullWidth
            onClose={handleDialogToggle}
        >
            <div className="detail-dialog-title">
                <Icon
                    name="schedule"
                    color="primary"
                    style={{ margin: "auto" }}
                />
                <div className="dialog-title-text">
                    {dayjs(activity.start).format("dddd DD MMMM")}
                </div>
                <div
                    className="dialog-close-button"
                    onClick={handleDialogToggle}
                >
                    <Icon name="close" color="primary" />
                </div>
            </div>
            <div className="detail-dialog-content-wrapper" style={{ border: `4px solid #${activity.activityType.backColor}`}}>
                <DialogContent
                    className="detail-dialog-content"
                    style={{
                        paddingTop: "15px",
                        overflowX: "hidden",
                    }}
                >
                    {teamScheduleError ? (
                        <NoShiftCard />
                    ) : isLoading ? (
                        <Spinner type="dots" />
                    ) : activityData ? (
                        <ShiftDetailCardContent
                            shift={detailsObject ?? activity}
                        />
                    ) : (
                        <NoShiftCard />
                    )}
                </DialogContent>
            </div>
        </Dialog>
    );
};

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
