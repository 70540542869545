import { DayInterface } from "../../../../interfaces/day.interface";
import { HolidayInterface } from "../../../../interfaces/holiday.interface";
import { ScheduleTimelineItem } from "../schedule-timeline-item/schedule-timeline-item";

interface ScheduleTimelineProps {
    schedule: DayInterface[]
    holidays: HolidayInterface[]
}

export function ScheduleTimeline(props: ScheduleTimelineProps) {
    const items = props.schedule.map((day: DayInterface, i: number) => (
        <ScheduleTimelineItem holidays={props.holidays.filter((holiday) => holiday.holidayDate === day.date)} day={day} key={i} />
    ))

    return (
        <div className="o4c timeline">
            {items}
        </div>
    )
}