import axios, { AxiosRequestConfig } from "axios";
import { useHistory } from "react-router-dom";
import { useAuth } from "../auth/use-auth";

export function useAPI() {
    const history = useHistory();

    // Create our Axios instance
    const api = axios.create({
        withCredentials: true
    });

    // List of calls waiting on our refresh cycle
    const refreshSubscribers: { resolve: any, reject: any }[] = [];

    // Indidates if we're currently refreshing tokens
    let isRefreshing = false;
    let { token, reAuthenticate, setToken } = useAuth();

    /**
     * Utility function to add a failed request to the queue
     * waiting on the refresh cycle
     * @param resolve 
     * @param reject 
     */
    const subscribeTokenRefresh = (resolve: any, reject: any) => {
        refreshSubscribers.push({ resolve, reject });
    }

    /**
     * Gets called when the token is refreshed to work through the queue
     */
    const onRefreshed = (token: any) => {
        while (refreshSubscribers.length > 0) {
            const subscriber = refreshSubscribers.pop();
            subscriber?.resolve(token);
        }
    }

    /**
     * Gets called when the token failed to refresh to clear the queue
     */
    const onExpired = () => {
        while (refreshSubscribers.length > 0) {
            const subscriber = refreshSubscribers.pop();
            subscriber?.reject();
        }
    }

    /**
     * Intercept requests and add our token
     */
    const requestInterceptor = (config: AxiosRequestConfig) => {
        // Only set header if no header was present!
        // Otherwise use the existing header from the refresh cycle!
        if (token && !config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    };

    /**
     * Intercept responses to listen for 401 errors to start our 
     * refresh cycle
     */
    const responseInterceptor = (error: any) => {
        // Save a copy of our original request
        const originalRequest = error.config;
        // Determine if this was a refresh request
        const isRefreshRequest = error.config.url === '/api/auth/token';

        // Check if refresh token was invalid - Here everything breaks!?
        if ([401, 403, 500].includes(error.response.status) && isRefreshRequest) {
            reAuthenticate();
            history.push('/login');
        }

        // Check if our access token has expired
        if (error.response.status === 401) {
            // Check if we're not already refreshing
            if (!isRefreshing) {
                // Indicate that we're refreshing
                isRefreshing = true;
                // Start the request cycle
                api.post(`/api/auth/token`, {})
                    .then(response => {
                        if (response && response.data) {
                            // Store our new token
                            setToken(response.data.accessToken);
                            // Set our new token as the default
                            const { accessToken } = response.data;
                            api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                            // Trigger calls in the queue with our new token
                            onRefreshed(accessToken);
                        }
                    })
                    .catch(error => {
                        // Log the error
                        console.error(error);
                        // Indidate we're no long refreshing
                        isRefreshing = false;
                        // Trigger to clear the queue
                        onExpired();
                    })
            }
        }

        // Add our failed request to the retry queue
        const retryOriginalRequest = new Promise((resolve, reject) => {
            subscribeTokenRefresh((accessToken: any) => {
                originalRequest._retry = true;
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                return resolve(api(originalRequest))
            }, () => {
                originalRequest._retry = false;
                return reject(api(originalRequest));
            });
        })

        // Return our retry
        return retryOriginalRequest;
    };

    /**
     * Bind our interceptors
     */
    api.interceptors.request.use(requestInterceptor)
    api.interceptors.response.use(response => response, responseInterceptor)

    return {
        api,
        fetcher: (url: string) => api.get(url).then(res => res.data),
    }
}