import "./note-indicator.scss"
import note_indicator from "../../../assets/note_indicator.svg"

export const NoteIndicator = () => {
    return (
        <div className="indicator-column">
            <div className="indicator-spacer"></div>
            <img className="note-indicator" src={note_indicator} alt="note" />
        </div>
    );
};
