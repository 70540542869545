import { useState } from "react";

export enum LocalStorageTypes {
  String,
  Object,
  Number,
}

export function useLocalStorage(
  key: string,
  initialValue: any,
  type: LocalStorageTypes = LocalStorageTypes.String
) {
  const get = (key: string): string | null => {
    return localStorage.getItem(key);
  };
  
  const getObject = (key: string): any | null => {
    const value = localStorage.getItem(key);
    return value && value !== 'undefined' ? JSON.parse(value) : value;
  };
  
  const getNumber = (key: string): number | null => {
    try {
      const value = localStorage.getItem(key);
      if (!value) return null;
      return parseInt(value);
    } catch(error) {
      console.log('Error parsing int from local storage', error);
      return null;
    }
  };
  
  const set = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };
  
  const setObject = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  
  const setNumber = (key: string, value: number) => {
    localStorage.setItem(key, `${value}`);
  };

  const [storedValue, setStoredValue] = useState(() => {
    let storeValue;
    if (type === LocalStorageTypes.Number) {
      storeValue = getNumber(key);
      if (initialValue && initialValue !== storeValue) 
        setNumber(key, parseInt(initialValue));
    } else if (type === LocalStorageTypes.Object) {
      storeValue = getObject(key);
      if (initialValue && initialValue !== storeValue) 
        setObject(key, initialValue);
    } else if (type === LocalStorageTypes.String) {
      storeValue = get(key);
      if (initialValue && initialValue !== storeValue) 
        set(key, initialValue);
    }

    return storeValue || initialValue;
  })

  const setValue = (value: any) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    if (valueToStore === null || valueToStore === undefined)
      set(key, valueToStore)
    else if (type === LocalStorageTypes.Number)
      setNumber(key, parseInt(valueToStore));
    else if (type === LocalStorageTypes.Object)
      setObject(key, valueToStore);
    else if (type === LocalStorageTypes.String)
      set(key, valueToStore);
  }

  return [storedValue, setValue];
}