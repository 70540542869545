import {
    Dialog,
    DialogContent,
    Slide,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Icon } from "@o4c/plugin-components";
import React from "react";
import { FunctionComponent, ReactElement } from "react";

import styles from "./generic-dialog.module.scss";
import { useTranslation } from "react-i18next";

interface IGenericDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    isResourcePreferences: boolean;
    children: ReactElement;
}

export const GenericDialog: FunctionComponent<IGenericDialogProps> = (
    props: IGenericDialogProps
) => {
    const { isOpen, handleClose, isResourcePreferences, children } = props;

    const { t } = useTranslation();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Dialog
            open={isOpen}
            fullScreen={fullScreen}
            maxWidth="md"
            TransitionComponent={Transition}
            onClose={handleClose}
        >
            <div className={styles.preferencesDialogTitle}>
                <Icon
                    name={"filter"}
                    color="primary"
                    style={{ margin: "auto" }}
                />
                <div className={styles.titleText}>
                    {isResourcePreferences
                        ? `${t("resources.resourcePreferences")}`
                        : `${t("activities.activityPreferences")}`}
                </div>
                <div className={styles.dialogCloseButton} onClick={handleClose}>
                    <Icon name="close" color="primary" />
                </div>
            </div>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
