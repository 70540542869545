import dayjs from "dayjs";
import { FunctionComponent } from "react";
import { ActivityInterface } from "../../../interfaces/activity.interface";
import { TeamScheduleDate } from "../../../interfaces/day.interface";

interface IDayTileProps {
    date: TeamScheduleDate;
    userSchedule: ActivityInterface[];
}

export const DayTile: FunctionComponent<IDayTileProps> = (
    props: IDayTileProps
) => {
    const { date, userSchedule } = props;

    //Filtering activities for the given day tile
    // and sorting them based on their start time
    const filterSchedule = () => {
        const filteredSchedule = userSchedule.filter(
            (activity: ActivityInterface) => {
                return (
                    dayjs(date.date).isSameOrAfter(
                        dayjs(activity.start),
                        "day"
                    ) &&
                    dayjs(date.date).isSameOrBefore(dayjs(activity.end), "day")
                );
            }
        );

        return filteredSchedule.filter((activity: ActivityInterface) => {
            return (
                dayjs(date.date).isSameOrAfter(dayjs(activity.start), "day") &&
                dayjs(date.date).isSameOrBefore(dayjs(activity.end), "day")
            );
        });
    };

    return (
        <th>
            <div className="day-tile">
                <span className="dates-day">
                    {dayjs(date.date).format("dd")}
                </span>
                <div
                    className={
                        dayjs().isSame(dayjs(date.date), "day") ? "active" : "not-active"
                    }
                >
                    {dayjs(date.date).format("D")}
                </div>
                <div className="dates-shifts">
                    {filterSchedule().map(
                        (activity: ActivityInterface, innerIndex: number) => (
                            <ActivityIndicator
                                key={
                                    "activityIndicator" +
                                    activity.id +
                                    innerIndex
                                }
                                tileColor={`#${activity.activityType.backColor}`}
                            />
                        )
                    )}
                </div>
            </div>
        </th>
    );
};

const ActivityIndicator = (props: { tileColor: string }) => {
    const { tileColor } = props;

    return (
        <div
            className="shift-bullet"
            style={{
                backgroundColor: tileColor,
            }}
        ></div>
    );
};
