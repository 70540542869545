import './input-switch.scss';

interface IInputSwitchProps {
  value: boolean;
  setter: Function;
  icons?: string[];
}

export function InputSwitch(props: IInputSwitchProps) {
  const toggleValue = () => {
    props.setter(!props.value);
  }

  return (
    <div className={`input-switch ${props.value ? 'active' : 'inactive'}`}>
      <button onClick={toggleValue}>
        {props.icons && props.icons[0] ? <i className={`${props.icons[0]}`} /> : null}
      </button>
      <button onClick={toggleValue}>
      {props.icons && props.icons[1] ? <i className={`${props.icons[1]}`} /> : null}
      </button>
    </div>
  )
}