import './calendar.scss';
import dayjs, { Dayjs } from "dayjs";
import { CalendarWeek } from './calendar-week/calendar-week';
import { useTranslation } from 'react-i18next';
import { ICalendarEvent } from './calendar-event/calendar-event';
import { CalendarDay } from './calendar-day/calendar-day';
import React, { useEffect, useState } from 'react';
import { useAPI } from '../../../hooks/use-api';
import useSWR from 'swr';
import { HolidayInterface } from '../../../interfaces/holiday.interface';

export enum CalendarViewMode {
  Default = 'default',
  Slider = 'slider',
}

interface ICalendarProps {
  viewMode?: CalendarViewMode;
  currentDate?: Dayjs;
  events?: ICalendarEvent[];
  onDatePressed?: Function;
}

export interface ICalendarDay {
  date: Date;
  key: string;
  events: ICalendarEvent[];
  isToday: boolean;
  isOtherMonth: boolean;
  isTomorrow: boolean;
  isWeekend: boolean;
  isSelected: boolean;
  isHoliday: boolean;
}

export function Calendar(props: ICalendarProps) {
  const { t } = useTranslation();
  const [previousDate, setPreviousDate] = useState(dayjs());
  let { fetcher } = useAPI();
  const { data: holidayData, error: holidayError } = useSWR<any>(`/api/holidays/?year=${dayjs(props.currentDate).clone().format("YYYY")}`, fetcher)

  const currentDate = props.currentDate || dayjs();
  const viewMode = props.viewMode || CalendarViewMode.Default;
  const events = props.events || [];
  const sliderCalendarRef = React.createRef<HTMLDivElement>();
  const selectedDayRef = React.createRef<HTMLButtonElement>();

  useEffect(() => {
    const shouldScroll = !previousDate.isSame(currentDate, 'day');
    if (sliderCalendarRef.current && selectedDayRef.current && shouldScroll) {
      setPreviousDate(currentDate);
      const offset = selectedDayRef.current.offsetLeft || 0;
      const elementWidth = selectedDayRef.current.clientWidth || 0;
      const scrollTo = (offset + (elementWidth / 2)) - (window.innerWidth / 2);
      sliderCalendarRef.current.scroll({
        left: scrollTo,
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [selectedDayRef, sliderCalendarRef, currentDate, previousDate])

  const today = dayjs();
  const tomorrow = today.add(1, 'day');
  const daysInWeek = 7;
  const calendarWeekendDays = [0, 6];

  const previousMonth = currentDate.subtract(1, 'month').endOf('month').startOf('day');
  const nextMonth = currentDate.add(1, 'month').startOf('month').startOf('day');

  const generateDay = (date: Dayjs) => {
    const calendarItem: any = {
      date: date.toDate(),
      key: date.format('YYYYMMDD'),
      events: events.filter(event => dayjs(event.startTime).isSame(date, 'day')),
    };

    if (date.isSame(today, 'day')) calendarItem.isToday = true;
    if (!date.isSame(currentDate, 'month')) calendarItem.isOtherMonth = true;
    if (date.isSame(tomorrow, 'day')) calendarItem.isTomorrow = true;
    if (calendarWeekendDays.includes(date.day())) calendarItem.isWeekend = true;
    if (date.isSame(currentDate, 'day')) calendarItem.isSelected = true;
    if (holidayData.holidays.filter((holiday: HolidayInterface) => holiday.holidayDate === date.format('YYYY-MM-DD')).length > 0) calendarItem.isHoliday = true;

    return calendarItem;
  }

  const generateWeek = (date: Dayjs) => {
    const week = Array(daysInWeek);
    for (let i = 0; i < daysInWeek; i++) {
      week[i] = generateDay(dayjs(date).add(i, 'day'));
    }
    return week;
  }

  const generateMonth = () => {
    const month = [];

    let startDay = currentDate.startOf('month').startOf('isoWeek').startOf('day');
    let endDay = currentDate.endOf('month').endOf('isoWeek').endOf('day');

    if (viewMode === CalendarViewMode.Slider) {
      startDay = currentDate.startOf('month').startOf('day');
      endDay = currentDate.endOf('month').endOf('day');
    }

    const amountOfWeeks = endDay.diff(startDay, 'week') + 1;

    for (let i = 0; i < amountOfWeeks; i++) {
      let date = startDay.add(i, 'week')
      if (date.isBefore(endDay)) {
        let week = generateWeek(date);
        month.push(week);
      }
    }

    return month;
  }

  const month = holidayData ? generateMonth() : undefined;

  function PreviousMonthButton() {
    const onClick = () => {
      if (props.onDatePressed) {
        props.onDatePressed(previousMonth.toDate())
      }
    }
    return (
      <button onClick={onClick} className="calendar-previous-month">
        <i className="icon-arrow-left leading" />{previousMonth.format('MMMM')}
      </button>
    )
  }

  function NextMonthButton() {
    const onClick = () => {
      if (props.onDatePressed) {
        props.onDatePressed(nextMonth.toDate())
      }
    }
    return (
      <button onClick={onClick} className="calendar-previous-month">
        {nextMonth.format('MMMM')}<i className="icon-arrow-right trailing" />
      </button>
    )
  }

  if (viewMode === CalendarViewMode.Slider) {
    return (
      <div ref={sliderCalendarRef} className={`calendar ${viewMode}`}>
        <PreviousMonthButton />
        {month ? month.map((week: ICalendarDay[]) => (
          week.map((day: ICalendarDay, i: number) => (
            <CalendarDay ref={day.isSelected ? selectedDayRef : null} onDatePressed={props.onDatePressed} day={day} showDayLabel={true} key={i} />
          ))
        )) : null}
        <NextMonthButton />
      </div>
    )
  }

  return (
    <div className={`calendar ${viewMode}`}>
      <div className="calendar-weekdays">
        {month ? month[0].map((day: ICalendarDay, i: number) => (<div key={i}>{t(`calendar.weekDays.${dayjs(day.date).format('d')}`)}</div>)) : null}
      </div>
      {month ? month.map((week: ICalendarDay[], i: number) => (
        <CalendarWeek onDatePressed={props.onDatePressed} week={week} key={i} />
      )) : null}
    </div>
  )
}
