import { NavLink } from 'react-router-dom';

interface TabsProps {
  items: { to: string; title: string; }[];
}

export function TabNavigation(props: TabsProps) {
  return <div className="o4c tabs">
    {
      props.items.map((item, i) => <NavLink key={`tab-${i}`} to={item.to} className="tab" activeClassName="active">{item.title}</NavLink>)
    }
  </div>;
}