import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICalendarDay } from "../calendar";
import { CalendarEvent, ICalendarEvent } from "../calendar-event/calendar-event";

interface ICalendarDayProps {
  day: ICalendarDay;
  showDayLabel?: boolean;
  onDatePressed?: Function;
}

export const CalendarDay = React.forwardRef<HTMLButtonElement, ICalendarDayProps>((props: ICalendarDayProps, ref) => {
  const { t } = useTranslation();
  const showDayLabel = props.showDayLabel || false;

  let cssClasses = '';
  if (props.day.isOtherMonth) cssClasses += ' other-month';
  if (props.day.isSelected) cssClasses += ' selected';
  if (props.day.isToday) cssClasses += ' today';
  if (props.day.isTomorrow) cssClasses += ' tomorrow';
  if (props.day.isWeekend) cssClasses += ' weekend';
  if (props.day.isHoliday) cssClasses += ' holiday';

  const onClick = () => {
    if (props.onDatePressed) {
      props.onDatePressed(props.day.date);
    }
  }

  return (
    <button ref={ref} onClick={onClick} className={`calendar-day${cssClasses}`}>
      {showDayLabel ? <div className="day-label">{t(`calendar.weekDays.${dayjs(props.day.date).format('d')}`)}</div> : null}
      <div className="date">{dayjs(props.day.date).format('D')}</div>
      {props.day.events ? props.day.events.map((event: ICalendarEvent, i: number) => (<CalendarEvent {...event} key={i} />)) : null}
    </button>
  )
});