import './shift-resources.scss';
import dayjs from "dayjs"
import { MemoLeaf } from '../../../shared/memo-leaf/memo-leaf';

interface ShiftResourcesInterface {
    shift: any
}
export function ShiftResources(props: ShiftResourcesInterface) {
    return (

        <div className="shift-resources">
            {
                props.shift.activities.map((resource: any, index: any) => {
                    return <div className={`shift-resource ${index === 0 && (resource.start !== props.shift.start || resource.end !== props.shift.end) ? 'different-time' : undefined}`} key={index}>
                        <div className="display-name">
                            <span>{resource.activityType.displayName}</span>
                        </div>
                        <div className="resource">
                            <span className="description">{resource.resource}</span>
                            <span>{dayjs(resource.start).format("HH:mm")} - {dayjs(resource.end).format("HH:mm")}</span>
                        </div>
                        {resource.memo && resource.memo !== "" ? <MemoLeaf>{resource.memo}</MemoLeaf> : null}
                    </div>
                })
            }
        </div>
    )
}

export default ShiftResources



