import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { TextField } from "@o4c/plugin-components";

import styles from "./preferences-dialog.module.scss";
import VerticalDndList from "../../../shared/vertical-dnd-list/vertical-dnd-list";
import { useTranslation } from "react-i18next";
import { Preference } from "../../../../interfaces/activity.interface";
import { PreferenceCard } from "../../../shared/preference-card/preference-card";

interface PreferencesDialogProps {
    visiblePreferences: Preference[];
    handlePreferencesChange: (s: Preference[]) => void;
    isResourcePreferences: boolean;
}

export const PreferencesDialog: FunctionComponent<PreferencesDialogProps> = (
    props: PreferencesDialogProps
) => {
    const {
        visiblePreferences,
        handlePreferencesChange,
        isResourcePreferences,
    } = props;

    const { t } = useTranslation();

    const [actives, setActives] = useState<Preference[]>(visiblePreferences);
    const [shownPreferences, setShownPreferences] =
        useState<Preference[]>(visiblePreferences);
    const [searchValue, setSearchValue] = useState<string>("");

    const canReorder = useMemo(() => {
        if (!searchValue) {
            return false;
        }

        return true;
    }, [searchValue]);

    useEffect(() => {
        if (!searchValue) {
            setShownPreferences(actives);
            return;
        }
        setShownPreferences(
            actives.filter((a) =>
                a.id.toLowerCase().includes(searchValue.toLowerCase())
            )
        );
        return;
    }, [searchValue, actives]);

    useEffect(() => {
        setActives(visiblePreferences);
        setShownPreferences(visiblePreferences);
    }, [visiblePreferences]);

    const handleSelectClick = () => {
        const activeStatusFound = actives.some((a) => a.isActive);
        handlePreferencesChange(
            actives.map((a) => ({ ...a, isActive: !activeStatusFound }))
        );
    };

    const handlePreferenceClick = (isActive: boolean, text: string) => {
        //On switch click on the preference change the is active status.
        handlePreferencesChange(
            actives.map((a) => {
                if (a.id === text) {
                    return { ...a, isActive: !isActive };
                }
                return a;
            })
        );
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    return (
        <>
            <div className={styles.searchField}>
                <TextField
                    label={
                        isResourcePreferences
                            ? `${t("resources.searchResourceTypes")}`
                            : `${t("activities.searchActivityTypes")}`
                    }
                    type="text"
                    name="preferenceSearch"
                    value={searchValue}
                    onChange={(e) => {
                        handleSearchChange(
                            e as React.ChangeEvent<HTMLInputElement>
                        );
                    }}
                />
            </div>
            <div className={styles.listLabel}>
                <div className={styles.listLabelText}>
                    {isResourcePreferences
                        ? `${t("resources.selectOrderAndResourceTypes")}`
                        : `${t("activities.selectOrderAndActivities")}`}
                </div>
                <div className={styles.selectAll} onClick={handleSelectClick}>
                    {actives.map((a) => a.isActive).includes(true)
                        ? `${t("activities.deselectAll")}`
                        : `${t("activities.selectAll")}`}
                </div>
            </div>
            <VerticalDndList
                items={shownPreferences}
                disabled={canReorder}
                onChange={(s) => {
                    handlePreferencesChange(s);
                }}
            >
                {(activity) => (
                    <PreferenceCard
                        text={activity.id}
                        canReorder={searchValue ? false : true}
                        isActive={activity.isActive}
                        handleChange={handlePreferenceClick}
                    />
                )}
            </VerticalDndList>
        </>
    );
};
