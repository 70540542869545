import dayjs from "dayjs";
import { ActivityInterface, Preference } from "../interfaces/activity.interface";
import { IUser } from "../auth/use-provide-auth";
import _ from "lodash";
import { DayPart, TeamScheduleDate } from "../interfaces/day.interface";
import { ResourceInterface, ResourceTypeMembership } from "../interfaces/resource.interface";
import { ITeamScheduleData } from "../components/views/team-schedule/team-schedule";

export const createUserSchedule = (
    activities: ActivityInterface[],
    user: IUser | null
) => {
    const userSchedule = activities.filter(
        (activity: ActivityInterface) =>
            activity.resourceId === user?.metadata.resourceId
    );
    return userSchedule;
};

export const createTeamSchedule = (
    teamScheduleData: ITeamScheduleData,
    user: IUser | null
) => {
    const { data, date: startDate, dayParts: _dayParts, resources: _resources } = teamScheduleData;
    let resources: ResourceInterface[] = [];

    const dates = createEmptyWeek(startDate);
    const dayParts = prepareDayPartsObject(_dayParts, dates);

    const { activities } = data;
    activities.forEach((activity: ActivityInterface) => {
        if(!activity.resource) return; //activity without resource is not displayed as a tile
        let formattedActivity = { ...activity };
        if (activity.rootId) {
            //get memo from root if exists (i need this for note indicator)
            const rootMemo = activities.find((a: ActivityInterface) => a.id === activity.rootId)?.memo
            formattedActivity = {
                ...formattedActivity,
                memo: activity?.memo ?? rootMemo ?? null, //leaf memo has priority against root.
                activities: activities.filter(
                    (a: ActivityInterface) => a.rootId === activity.rootId
                ),
            };
        }
        const resourceIndex = resources.findIndex(
            (resource) => resource.resourceId === activity.resourceId
        );
        if (resourceIndex === -1) {
            const _resource = _.find(_resources, r => r.id === activity.resourceId);
            if(!_resource) {
                return;
            }
            const tempDayParts = _.cloneDeep(dayParts);
            assignActivityToDayPart(tempDayParts, formattedActivity);
            resources.push({
                resourceId: _resource.id,
                resource: _resource.displayName ?? "",
                resourceTypeIds: _resource.resourceTypeMemberships.map((rt: ResourceTypeMembership) => rt.resourceTypeId),
                dayParts: tempDayParts,
            });

            return;
        }

        assignActivityToDayPart(
            resources[resourceIndex].dayParts,
            formattedActivity
        );
    });

    resources = sortResources(resources, user);
    return { resources, dates };
};

export const getClass = (index: number, dayPartLength: number) => {
    if (index === 0) {
        return "row-0";
    }

    if (index === dayPartLength - 1) {
        return "row-2";
    }

    return "row-1";
};

export const convertShiftsToCalendarEvents = (
    activities: ActivityInterface[]
): any[] => {
    const events: any[] = [];

    activities.forEach((shift) => {
        events.push({
            startTime: shift.start,
            endTime: shift.end,
            isAllDay: false,
            type: "shift",
            activityType: shift.activityType,
        });
    });

    return events;
};

export const createEmptyWeek = (startDate: string) => {
    let date = dayjs(startDate);
    const days = 7;
    const week: TeamScheduleDate[] = [];
    for (let day = 0; day < days; day++) {
        week.push({
            date: date.format("YYYY-MM-DD"),
            activities: [],
        });
        date = dayjs(date).add(1, "day");
    }
    return week;
};

export const createDayPartsObject = (
    dayParts: DayPart[],
    dates: TeamScheduleDate[]
) => {
    // If no day parts are configured, set a default empty day part for the whole day
    if (dayParts.length === 0) {
        return [
            {
                start: "00:00",
                end: "23:59",
                name: "",
                days: _.cloneDeep(dates),
            },
        ];
    }

    // Sort the day parts from earliest to latest
    const _dayParts = dayParts.sort((daypartA: DayPart, daypartB: DayPart) => {
        return daypartA.start > daypartB.start
            ? 1
            : daypartA.start < daypartB.start
            ? -1
            : 0;
    });

    return _dayParts.map((dayPart: DayPart) => ({
        ...dayPart,
        days: _.cloneDeep(dates),
    }));
};

export const prepareDayPartsObject = (
    _dayParts: DayPart[],
    dates: TeamScheduleDate[]
) => {
    const dayParts = createDayPartsObject(_dayParts, dates);

    // Set the end time of every day part to the start of the next day part
    for (let daypartIndex = 0; daypartIndex < dayParts.length; daypartIndex++) {
        dayParts[daypartIndex].end = dayParts[daypartIndex + 1]
            ? dayParts[daypartIndex + 1].start
            : "23:59";
    }

    return dayParts;
};

//Returns an array of days that overlap with a specific day part
export const findOverlappingDays = (
    dayPart: DayPart,
    activity: ActivityInterface
) => {
    const overlappingDays: string[] = [];
    const targetDays = [];

    let loopDate = dayjs(activity.start).clone();
    while (
        dayjs(activity.end).clone().format("YYYY-MM-DD") >=
        loopDate.format("YYYY-MM-DD")
    ) {
        targetDays.push(loopDate.format("YYYY-MM-DD"));
        loopDate = loopDate.add(1, "day");
    }

    const activityStartDatetime = dayjs(activity.start);
    const activityEndDatetime = dayjs(activity.end);

    targetDays.forEach((day) => {
        let dayPartStartDatetime = dayjs(`${day} ${dayPart.start}`);
        let dayPartEndDatetime = dayjs(`${day} ${dayPart.end}`);

        let isOverlapping =
            dayPartStartDatetime < activityEndDatetime &&
            activityStartDatetime < dayPartEndDatetime;

        if (isOverlapping) {
            overlappingDays.push(day);
        }
    });

    return overlappingDays;
};

export const assignActivityToDayPart = (
    dayParts: DayPart[],
    activity: ActivityInterface
) => {
    dayParts.forEach((daypart: DayPart) => {
        const overlappingDays = findOverlappingDays(daypart, activity);
        overlappingDays.forEach((day: string) => {
            const dayIndex = daypart.days.findIndex(
                (daypartDay: TeamScheduleDate) => daypartDay.date === day
            );
            if (daypart.days[dayIndex]) {
                daypart.days[dayIndex].activities.push({
                    ...activity,
                    dayPartStart: dayjs(`${day} ${daypart.start}`),
                });
            }
        });
    });
};

//First resource should be user's and then sorting goes
//alphabetically
export const sortResources = (
    resources: ResourceInterface[],
    user: IUser | null
) => {
    const userResource = resources.find(
        (resource: ResourceInterface) =>
            resource.resourceId === user?.metadata.resourceId
    );

    if (!userResource) {
        return sortResourcesAlphabetically(resources);
    }

    const resourcesWithoutUser = resources.filter(
        (resource) => resource.resourceId !== userResource.resourceId
    );

    const alphabetically = sortResourcesAlphabetically(resourcesWithoutUser)

    return [userResource, ...alphabetically];
};

const sortResourcesAlphabetically = (resources: ResourceInterface[]) => {
    return resources.sort((a, b) => a.resource.localeCompare(b.resource));
}

export const sortTeamScheduleActivities = (
    activityA: ActivityInterface,
    activityB: ActivityInterface
) => {
    const startDateA = dayjs(activityA.start);
    const startDateB = dayjs(activityB.start);

    const fromDayPartStartA = startDateA.diff(activityA.dayPartStart);
    const fromDayPartStartB = startDateB.diff(activityB.dayPartStart);

    //To achieve the wanted transition I sort based on what started
    //later on the previous day part
    if (fromDayPartStartA < 0 && fromDayPartStartB < 0) {
        return sortOnDayPartStart(fromDayPartStartA, fromDayPartStartB);
    }

    const startTimeSort = sortOnActivityStart(activityA, activityB);
    if (startTimeSort !== 0) {
        return startTimeSort;
    }

    //if start time is same we sort based on end time
    const endTimeSort = sortOnActivityEnd(activityA, activityB);
    if (endTimeSort !== 0) {
        return endTimeSort;
    }

    return sortAlphabetically(activityA, activityB);
};

const sortOnDayPartStart = (a: number, b: number) => {
    if (a > b) {
        return -1;
    }

    if (a < b) {
        return 1;
    }

    return 0;
};

const sortOnActivityStart = (
    activityA: ActivityInterface,
    activityB: ActivityInterface
) => {
    if (activityA.start < activityB.start) {
        return -1;
    }

    if (activityA.start > activityB.start) {
        return 1;
    }

    return 0;
};

const sortOnActivityEnd = (
    activityA: ActivityInterface,
    activityB: ActivityInterface
) => {
    if (activityA.end > activityB.end) {
        return -1;
    }

    if (activityA.end < activityB.end) {
        return 1;
    }

    return 0;
};

const sortAlphabetically = (
    activityA: ActivityInterface,
    activityB: ActivityInterface
) => {
    if (activityA.activityType.shortName < activityB.activityType.shortName) {
        return -1;
    }

    if (activityA.activityType.shortName > activityB.activityType.shortName) {
        return 1;
    }

    return 0;
};

export const createDaySchedule = (shifts: any) => {
    const schedule: any[] = [];
    let rootDate = null;
    shifts.activities.forEach(function (activity: ActivityInterface) {
        if (activity.rootId === null) {
            rootDate = new Date(activity.start.substr(0, 10)).toISOString();
            schedule.push({ ...activity, activities: [] });
        } else {
            let shiftIndex = schedule.findIndex(
                (element) => element.id === activity.rootId
            );
            schedule[shiftIndex]["activities"].push(activity);
        }
    });
    schedule.forEach((shift) => {
        // Filter out all activities that are not the root or a leaf activity
        const branchIds: any = [];
        shift.activities.forEach((activity: ActivityInterface) => {
            if (activity.rootId !== activity.parentId) {
                branchIds.push(activity.parentId);
            }
        });
        shift.activities = shift.activities.filter(
            (activity: ActivityInterface) => {
                return branchIds.indexOf(activity.id) === -1;
            }
        );

        // Sort the activities by the provided sort order
        shift.activities.sort(
            (activityA: ActivityInterface, activityB: ActivityInterface) =>
                activityA.activityType.sortOrder === null ||
                activityB.activityType.sortOrder === null
                    ? 0
                    : activityA.activityType.sortOrder >
                      activityB.activityType.sortOrder
                    ? 1
                    : activityA.activityType.sortOrder <
                      activityB.activityType.sortOrder
                    ? -1
                    : 0
        );
    });
    schedule.sort((a, b) =>
        new Date(a.start) > new Date(b.start)
            ? 1
            : new Date(a.start) < new Date(b.start)
            ? -1
            : 0
    );
    return { date: rootDate, shifts: schedule, shiftCount: schedule.length };
};

export const sortPreferences = (preferences: Preference[]): Preference[]  => {
    const activePreferences = preferences.filter(p => p.isActive);
    const nonActivePreferences = preferences.filter(p => !p.isActive).sort((a, b) => {
        return (a.id < b.id) ? -1 : (a.id > b.id) ? 1 : 0;
    });

    return _.concat(activePreferences, nonActivePreferences);
}
export const createDetailsObject = (activityTree: ActivityInterface[]) => {
    if (!activityTree) {
        return
    };
    const root = activityTree.find((a: ActivityInterface) => a.rootId === null);
    if(!root) {
        return;
    }
    return {
        ...root,
        activities: activityTree.filter(
            (e: ActivityInterface) => e.rootId != null && e.resource && e.resourceId
        ),
    };
};
