import { FunctionComponent } from "react";
import { DayPartRow } from "../../day-part-row/day-part-row";
import { ResourceInterface } from "../../../../interfaces/resource.interface";
import { DayPart } from "../../../../interfaces/day.interface";
import { ActivityInterface } from "../../../../interfaces/activity.interface";

interface IResourceTableContentProps {
    resource: ResourceInterface;
    handleClickTile: (activity: ActivityInterface) => void;
}
export const ResourceTableContent: FunctionComponent<
    IResourceTableContentProps
> = (props: IResourceTableContentProps) => {
    const { resource, handleClickTile } = props;

    return (
        <>
            {resource.dayParts.map((dayPart: DayPart, index: number) => (
                <DayPartRow
                    index={index}
                    key={"daypartRow" + index + dayPart.name}
                    dayPartsSize={resource.dayParts.length}
                    dayPart={dayPart}
                    handleClickTile={handleClickTile}
                />
            ))}
        </>
    );
};
