const handleErrors = (res: Response) => {
  if (!res.ok) {
    throw Error(res.statusText);
  }
  return res;
}

export const getMagazine = (token: string, magazineId: string): Promise<any> => {
  const url = `/api/config/${magazineId}`;
  const headers = {
    'Content-Type': 'application/json',
    'X-Magazine-Id': magazineId,
    'Authorization': token
  };
  return fetch(url, { headers }).then(handleErrors).then(res => res.json());
}
