import { ActivityInterface } from "../../../../interfaces/activity.interface";
import { NoteIndicator } from "../../../shared/note-indicator/note-indicator";
import OverviewShift from "../../../shared/overview-shift/overview-shift";
import "./shift-card.scss"

interface ShiftCardProps {
    shift: ActivityInterface;
}

export function ShiftCard(props: ShiftCardProps) {
    return (
        <div className="o4c card">
            <div className="main">
                <OverviewShift shift={props.shift}></OverviewShift>
            </div>
                {props.shift.memo && <NoteIndicator/>}
        </div>
    )
}