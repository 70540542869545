import { FunctionComponent, useState } from "react";
import { Preference } from "../../../interfaces/activity.interface";
import {
    ResourceFilter,
    ResourcePropertyWithValues,
} from "../../../interfaces/resource.interface";
import { Accordion, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import { PreferencesDialog } from "../../views/activities/activity-preferences/preferences-dialog";

import styles from "./team-schedule-filters.module.scss";
import { useTranslation } from "react-i18next";
import { FiltersDialog } from "../filters-dialog/filters-dialog";

interface ITeamScheduleFiltersProps {
    visiblePreferences: Preference[];
    handlePreferencesChange: (s: Preference[]) => void;
    filters?: ResourceFilter;
    propertiesObject: ResourcePropertyWithValues[];
    handleFiltersChange: (f: ResourceFilter) => void;
}

export const TeamScheduleFilters: FunctionComponent<
    ITeamScheduleFiltersProps
> = (props: ITeamScheduleFiltersProps) => {
    const {
        visiblePreferences,
        handlePreferencesChange,
        filters,
        propertiesObject,
        handleFiltersChange,
    } = props;

    const { t } = useTranslation();

    const preferencesSelected = visiblePreferences.filter(
        (p) => p.isActive
    ).length;

    const valuesSelected = filters?.actives.filter((f) => f.isActive).length ?? 0;

    const hasActiveResourceTypes = _.some(
        visiblePreferences,
        (p: Preference) => p.isActive
    );

    const [resourceTypesOpen, setResourceTypesOpen] = useState<boolean>(
        !hasActiveResourceTypes
    );
    const [resourcePropertiesOpen, setResourcePropertiesOpen] =
        useState<boolean>(hasActiveResourceTypes);

    const handleResourceTypesClick = () => {
        setResourceTypesOpen(!resourceTypesOpen);
        setResourcePropertiesOpen(false);
    };

    const handleResourcePropertiesClick = () => {
        setResourcePropertiesOpen(!resourcePropertiesOpen);
        setResourceTypesOpen(false);
    };

    return (
        <div>
            <Accordion
                expanded={resourceTypesOpen}
                square={true}
                style={{ width: "100%" }}
            >
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon style={{ color: `var(--primary)` }} />
                    }
                    onClick={handleResourceTypesClick}
                    aria-controls={`resource-type-preferences-content`}
                    id={`resource-type-preferences-header`}
                    style={{ borderBottom: `1px solid var(--secondary);` }}
                >
                    <AccordionTitle
                        label={`${t("resources.resourceTypes")}`}
                        valuesSelected={preferencesSelected}
                    />
                </AccordionSummary>
                <div className={styles.accordionContent}>
                    <PreferencesDialog
                        handlePreferencesChange={handlePreferencesChange}
                        visiblePreferences={visiblePreferences}
                        isResourcePreferences={true}
                    />
                </div>
            </Accordion>
            <Accordion expanded={resourcePropertiesOpen} square={true}>
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon style={{ color: "var(--primary)" }} />
                    }
                    onClick={handleResourcePropertiesClick}
                    aria-controls={`resource-property-filters-content`}
                    id={`resource-property-filters-header`}
                >
                    <AccordionTitle
                        label={`${t("resources.resourceProperties")}`}
                        valuesSelected={valuesSelected}
                    />
                </AccordionSummary>
                <div className={styles.accordionContent}>
                    <FiltersDialog
                        propertiesObject={propertiesObject}
                        filters={filters}
                        handleFiltersChange={handleFiltersChange}
                    />
                </div>
            </Accordion>
        </div>
    );
};

interface IAccordionTitleProps {
    label: string;
    valuesSelected: number;
}

const AccordionTitle: FunctionComponent<IAccordionTitleProps> = (
    props: IAccordionTitleProps
) => {
    const { label, valuesSelected } = props;
    const hasSelected = valuesSelected > 0;

    const { t } = useTranslation();

    return (
        <div className={styles.accordionTitle}>
            <div className={styles.titleLabel}>
                <div className={styles.label}>{label}</div>
                {hasSelected ? (
                    <div
                        className={styles.filterSelected}
                    >{`${valuesSelected} ${t("resources.selected")}`}</div>
                ) : null}
            </div>
        </div>
    );
};
