import { FunctionComponent } from "react";
import { ResourceInterface } from "../../../../interfaces/resource.interface";


interface IResourceTableHeaderProps {
    resource: ResourceInterface;
}
export const ResourceNameLabel: FunctionComponent<IResourceTableHeaderProps> = (
    props: IResourceTableHeaderProps
) => {
    const { resource } = props;

    return (
        <tr className="name">
            <td className="name-cell" colSpan={3}>
                {resource.resource}
            </td>
            <td colSpan={5}></td>
        </tr>
    );
};
