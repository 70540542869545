import { FunctionComponent } from "react";
import { sortTeamScheduleActivities } from "../../../../services/schedule";
import { ActivityTile } from "../../../views/activity-tile/activity-tile";
import { ActivityInterface } from "../../../../interfaces/activity.interface";
import { TeamScheduleDate } from "../../../../interfaces/day.interface";

interface IDayPartActivitiesProps {
    day: TeamScheduleDate;
    handleClickTile: (activity: ActivityInterface) => void;
}
export const DayPartActivities: FunctionComponent<IDayPartActivitiesProps> = (
    props: IDayPartActivitiesProps
) => {
    const { day, handleClickTile } = props;
    return (
        <td className="tile-td">
            <div className="tile-activities">
                {day.activities
                    .sort(sortTeamScheduleActivities)
                    .map((activity: ActivityInterface, index: number) => (
                        <ActivityTile
                            index={index}
                            key={"activityTile" + index + activity.id}
                            handleClickTile={handleClickTile}
                            tileData={{
                                activity,
                            }}
                        />
                    ))}
            </div>
        </td>
    );
};
