import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs'
import { Link, useHistory, useParams } from 'react-router-dom';
import useSWR from "swr";
import { useAPI } from '../../../hooks/use-api';
import { FullScreenLoader } from '../../shared/full-screen-loader/full-screen-loader';
import { TopSection } from '../../shared/top-section/top-section';
import { ScheduleTimeline } from './schedule-timeline/schedule-timeline';
import './schedule.scss'
import { Calendar } from '../../shared/calendar/calendar';
import { InputSwitch } from '../../shared/input-switch/input-switch';
import { convertShiftsToCalendarEvents } from '../../../services/schedule';

const ScheduleList = () => {
    let params: any = useParams();
    const { t } = useTranslation();
    let { fetcher } = useAPI();
    const history = useHistory();

    const [date, setDate] = useState<Dayjs>(params.date ? dayjs(params.date) : dayjs())
    const [showCalendar, setShowCalendar] = useState<boolean>(false);

    const { data: scheduleData, error: scheduleError } = useSWR<any>(`/api/schedules/month?startDate=${date.clone().startOf('month').format("YYYY-MM-DD")}&endDate=${date.clone().endOf('month').format("YYYY-MM-DD")}`, fetcher)

    const { data: holidayData, error: holidayError } = useSWR<any>(`/api/holidays/?year=${date.clone().startOf('month').format("YYYY")}`, fetcher)

    useEffect(() => {
        setDate(params.date ? dayjs(`${params.date}`) : dayjs())
    }, [params]);

    useEffect(() => {
        document.title = t('schedule.title')
    })

    if (scheduleError || holidayError) return <div>Failed to load!</div>;


    const events: any = convertShiftsToCalendarEvents(scheduleData?.activities || [])

    const onDatePressed = (date: Date) => {
        history.push(`/schedule/${dayjs(date).format('YYYY-MM-DD')}/details`);
    }

    const createEmptyMonthSchedule = (startDate: string) => {
        const date = new Date(startDate);
        const month = new Date(startDate).getMonth();
        const schedule = [];
        while (date.getMonth() === month) {
            schedule.push({ date: dayjs(date).format("YYYY-MM-DD"), shifts: [], shiftCount: 0 });
            date.setDate(date.getDate() + 1);
        }
        return schedule;
    }

    const createMonthSchedule = (shifts: any, startDate: string) => {
        const filteredStartDate = dayjs().isSame(startDate, 'month') ? dayjs().format("YYYY-MM-DD") : startDate
        const schedule: any = createEmptyMonthSchedule(filteredStartDate)
        shifts.activities.forEach(function (activity: any) {
            let day = dayjs(activity.start).format("YYYY-MM-DD")
            let dayIndex = schedule.findIndex((element: any) => element.date === day);
            if (dayIndex > -1) {
                schedule[dayIndex].shiftCount++
                schedule[dayIndex]['shifts'].push(activity)
                schedule[dayIndex]['shifts'].sort((a: any, b: any) => new Date(a.start) > new Date(b.start) ? 1 : new Date(a.start) < new Date(b.start) ? -1 : 0)
            }
        });
        schedule.sort((a: any, b: any) => new Date(a.date) > new Date(b.date) ? 1 : new Date(a.date) < new Date(b.date) ? -1 : 0)
        return { days: schedule };
    }

    return <>
        <>
            <TopSection>
                <div className="screen-header">
                    <div className="switch">
                        <InputSwitch
                            value={showCalendar}
                            setter={setShowCalendar}
                            icons={['icon-list', 'icon-schedule']}
                        />
                    </div>
                    <div>
                        <Link to={`/schedule/${date.clone().subtract(1, 'month').startOf('month').startOf('month').format('YYYY-MM-DD')}`}><i className="icon-arrow-left"></i></Link>
                        <div>{date.clone().format('MMMM YYYY')}</div>
                        <Link to={`/schedule/${date.clone().add(1, 'month').startOf('month').format('YYYY-MM-DD')}`}><i className="icon-arrow-right"></i></Link>
                    </div>
                </div>
                <div className={`calendar-container ${showCalendar ? 'show' : 'hide'}`}>
                    <Calendar
                        onDatePressed={onDatePressed}
                        currentDate={date}
                        events={events}
                    />
                </div>
            </TopSection>
            {!scheduleData || !holidayData ? <FullScreenLoader /> :
                <ScheduleTimeline holidays={holidayData.holidays} schedule={createMonthSchedule(scheduleData, date.clone().startOf('month').format("YYYY-MM-DD")).days} />
            }
        </>

    </>;
}
export default ScheduleList