import { Icon } from "@o4c/plugin-components";
import { Dayjs } from "dayjs";
import { FunctionComponent } from "react";
import headerStyle from "./activities-header.module.scss";

interface IActivitiesHeaderProps {
    date: Dayjs;
    isCalendarOpen: boolean;
    handleCalendarClick: () => void;
    handleDateChange: (date: Dayjs) => void;
    handleCalendarChange: (date: Dayjs) => void;
    onPreferencesClick: () => void;
}

const ActivitiesHeader: FunctionComponent<IActivitiesHeaderProps> = ({
    date,
    handleDateChange,
    handleCalendarChange,
    handleCalendarClick,
    isCalendarOpen,
    onPreferencesClick
}) => {

    const handleLeftArrowClick = () => {
        isCalendarOpen
            ? handleCalendarChange(date.subtract(1, "month"))
            : handleDateChange(date.clone().subtract(1, "day"));
    };

    const handleRightArrowClick = () => {
        isCalendarOpen
            ? handleCalendarChange(date.add(1, "month"))
            : handleDateChange(date.clone().add(1, "day"));
    };

    return (
        <div className={headerStyle.activitiesHeader}>
            <div
                className={`o4c button outline default inline small ${headerStyle.iconBtn}`}
                onClick={handleCalendarClick}
            >
                <Icon name="schedule" />
            </div>
            <div className={headerStyle.dateActions}>
                <div
                    className={headerStyle.dateBtn}
                    onClick={handleLeftArrowClick}
                >
                    <Icon name="arrow-left" />
                </div>
                <div className={headerStyle.dateText}>
                    {isCalendarOpen ? date.startOf("month").format("MMMM YYYY") : date.format("dddd DD MMMM") }
                </div>
                <div
                    className={headerStyle.dateBtn}
                    onClick={handleRightArrowClick}
                >
                    <Icon name="arrow-right" />
                </div>
            </div>
            <div className={`o4c button outline default inline small ${headerStyle.iconBtn}`} onClick={onPreferencesClick}>
                <Icon name='filter' size='small'/>
            </div>
        </div>
    );
};

export default ActivitiesHeader;
