import { useTranslation } from 'react-i18next';
import { useQuery } from "../../../hooks/use-query";
import { TabNavigation } from '../tab-navigation/tab-navigation';

export function TopSection({ children }: any) {
  const query = useQuery()
  const { t } = useTranslation();

  const navLinks = [
    { to: `/schedule?${query.toString()}`, title: t('schedule.title') },
    { to: `/team-schedule?${query.toString()}`, title: t('teamSchedule.title') },
    { to: `/activities?${query.toString()}`, title: t('activities.title') }
  ];
  return (
    <nav className="top-navigation">
      <TabNavigation items={navLinks} />
      {children}
    </nav>
  )
}