import React from "react";
import {
    DragDropContext,
    Droppable,
    Draggable,
    OnDragEndResponder,
} from "react-beautiful-dnd";

const nonce =
    (document.querySelector('meta[property="csp-nonce"]') as any)?.content ||
    "";

export default function VerticalDndList<T extends { id: string }>(props: {
    items: T[];
    children: (item: T) => JSX.Element;
    onChange: (items: T[]) => void;
    droppableId?: string;
    disabled?: boolean;
    itemWrapperClassName?: string; // used to add item gap via ::after
}) {
    const onDragEnd: OnDragEndResponder = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        // reorder using index of source and destination.
        const schemaCopy = props.items.slice();
        const [removed] = schemaCopy.splice(result.source.index, 1);
        // put the removed one into destination.
        schemaCopy.splice(result.destination.index, 0, removed);
        props.onChange(schemaCopy);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} nonce={nonce}>
            <Droppable
                droppableId={props.droppableId || "a_droppable_id"}
                // renderClone={renderItemFn}
                isDropDisabled={props.disabled}
            >
                {(provided, snap) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {props.items.map((it, i) => (
                            <Draggable
                                key={it.id}
                                draggableId={it.id}
                                index={i}
                                isDragDisabled={props.disabled}
                            >
                                {(provided, snapshot, rubric) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        className={props.itemWrapperClassName}
                                    >
                                        {props.children(
                                            props.items[rubric.source.index]
                                        )}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
