import { FunctionComponent } from "react";
import { ResourceNameLabel } from "./resource-name-label/resource-name-label";
import { ResourceTableContent } from "./resource-table-content/resource-table-content";
import { ResourceInterface } from "../../../interfaces/resource.interface";
import { ActivityInterface } from "../../../interfaces/activity.interface";

interface IResourceTableProps {
    resource: ResourceInterface;
    handleClickTile: (activity: ActivityInterface) => void;
}
export const ResourceTable: FunctionComponent<IResourceTableProps> = (
    props: IResourceTableProps
) => {
    const { resource, handleClickTile } = props;
    return (
        <>
            <ResourceNameLabel resource={resource} />
            <ResourceTableContent resource={resource} handleClickTile={handleClickTile} />
        </>
    );
};


