import dayjs from "dayjs";
import { DbDayPart } from "../../../interfaces/day.interface";
import { ActivityInterface } from "../../../interfaces/activity.interface";
import _ from "lodash";

export type Source = {
    rootId: number,
    lines: string[]
    hasMemo: boolean,
}

export type SourceObject = {
    sources: Source[]
}

export interface IGroupedActivities {
    [key: string]: GroupedActivityValue
}

export interface GroupedActivityValue {
    id: string,
    backColor: string,
    isActive: boolean,
    [key: string]: SourceObject | string | boolean,
}

interface IGroupedActivitiesByDisplayName {
    [key: string]: ActivityInterface[]
}


interface InnerGrouped {
    [key: string]: {
        backColor: string;
        textColor: string;
        sources: string[];
        dayParts: string[];
        hasMemo?: boolean;
    }
};
interface IgroupedByRootId {
    [key: string]: InnerGrouped
}

function getDaypartLabels(start: string, end: string, dayParts: DbDayPart[]): string[] {
    // get start and end hour as int
    const startHour = dayjs(start).hour();
    const endHour = dayjs(end).hour() === 0 ? 24 : dayjs(end).hour();

    // sort dayParts by start value to set start and end value for day parts
    dayParts.sort((a, b) => a.start.localeCompare(b.start));

    // create time slots based on dayParts, converting start and end times to integers
    const timeSlots = dayParts.map((dp: DbDayPart, index: number) => ({
        name: dp.name,
        start: parseInt(dp.start),
        end: index === dayParts.length - 1 ? 24 : parseInt(dayParts[index + 1].start),
    }));

    // check if the start or end time falls within time slot
    // and add the name to dayparts, may result multiple daypart for single activity

    return timeSlots
        .filter(ts => (startHour >= ts.start && startHour < ts.end) || (endHour > ts.start && endHour <= ts.end))
        .map(ts => ts.name);
}

function getCardSources(activities: ActivityInterface[]) {
    return activities.map((activity) => `${activity.activityType.shortName} - ${activity.resource?.split("-")[0]}`);
}

function memoExists(activities: ActivityInterface[], rootActivity?: ActivityInterface) {
    if(!rootActivity) {
        return activities.some(activity => Boolean(activity.memo));
    }

    const filtered = activities.filter(a => a.rootId === rootActivity.id);
    return filtered.some(activity => Boolean(activity.memo)) || Boolean(rootActivity?.memo);
}

function groupByRootId(groupedByDisplayName: IGroupedActivitiesByDisplayName, roots: ActivityInterface[], dayParts: DbDayPart[]): IgroupedByRootId {
    return _.mapValues(groupedByDisplayName, (activityArray) => {
        const groupsByRootId = _.uniq(activityArray.map(a => a.rootId)).map(rootId => {
            const rootKey = rootId!.toString();
            const rootData = activityArray.find(activity => activity.rootId === rootId);
            const leafActivities = activityArray.filter(a => a.rootId === rootId);
            if (!rootData) return null;

            const rootActivity = roots.find(r => r.id === rootId);
            const { start, end, activityType: { backColor, textColor } } = rootData;
            return {
                [rootKey]: {
                    backColor,
                    textColor,
                    sources: getCardSources(leafActivities),
                    dayParts: getDaypartLabels(start, end, dayParts),
                    hasMemo: memoExists(leafActivities, rootActivity)
                }
            };
        });

        const groupsByRootIdFiltered = groupsByRootId.filter(Boolean);
        return _.merge({}, ...groupsByRootIdFiltered);
    })
}

function groupByDayPart(data: IgroupedByRootId, dayParts: DbDayPart[]) {
    return (_.mapValues(data, (currentParentObject, commonPrefix) => {
        const backColor = Object.values(currentParentObject)[0]?.backColor
        const textColor = Object.values(currentParentObject)[0]?.textColor

        const isActive = false;
        const dayPartSources = _.map(dayParts, (dp) => {
            const sources = _.chain(currentParentObject)
                .pickBy((rootObj) => rootObj.dayParts.includes(dp.name))
                .map((rootObj, rootId) => ({
                    rootId,
                    lines: rootObj.sources,
                    hasMemo: rootObj.hasMemo,
                }))
                .value();


            return {
                [dp.name]: { sources }
            };
        });

        return _.merge({ backColor, textColor, id: commonPrefix, isActive }, ...dayPartSources);
    }))
}

function assignActivitiesToRoots(
    activities: ActivityInterface[], 
    rootsObject: IGroupedActivitiesByDisplayName
): IGroupedActivitiesByDisplayName {

    const result = _.mapValues(rootsObject, (parentActivities: ActivityInterface[]) => {
        const leafActivities = parentActivities.flatMap((root: ActivityInterface) => {
            //If it's a leaf activity I compare with the rootId otherwise with the id
            const activitiesForRoot = activities.filter((activity: ActivityInterface) => {
                return activity.rootId === root.id || 
                    activity.id === root.id
            });
            return activitiesForRoot;
        });
        

        //If an activity does not have rootId but have a resource I add id as rootId to use it later
        const mapRootId = leafActivities.map(activity => {
            return {
                ...activity,
                rootId: activity.rootId ? activity.rootId : activity.id
            }
        });

        return mapRootId;
    });

    return result;
}

function groupByDisplayName (
    data: ActivityInterface[], 
    roots:ActivityInterface[]
): IGroupedActivitiesByDisplayName {
    const rootsObject = _.groupBy(roots, a => a.activityType.displayName);
    return assignActivitiesToRoots(data, rootsObject);
}

export function createActivitiesObject(data: ActivityInterface[], dayParts: DbDayPart[]): IGroupedActivities {
    //An activity without rootId is a root activity
    const rootActivities = data.filter(d => !d.rootId);

    //An activity is assigned to a resource
    const assignedActivities = data.filter(activity => Boolean(activity.resource) && activity.resourceId);

    const groupedByDisplayName = groupByDisplayName(assignedActivities, rootActivities);
    const groupedByRootId: IgroupedByRootId = groupByRootId(groupedByDisplayName, rootActivities, dayParts);
    
    return groupByDayPart(groupedByRootId, dayParts);
}