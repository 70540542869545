import { Card } from "@o4c/plugin-components";
import { FunctionComponent } from "react";
import Toggle from "react-toggle";

import iconMove from "../../../assets/icon_move.svg";
import styles from "./preference-card.module.scss"

interface IPreferenceCardProps {
    text: string;
    isActive: boolean;
    canReorder: boolean;
    handleChange: (isActive: boolean, text: string) => void;
}
export const PreferenceCard: FunctionComponent<
    IPreferenceCardProps
> = (props: IPreferenceCardProps) => {
    const { isActive, text, canReorder, handleChange } = props;

    const _handleChange = () => {
        handleChange(isActive, text);
    };

    return (
        <Card>
            <div className={styles.preferenceCard}>
                {canReorder && <img src={iconMove} alt="" />}
                <div className={styles.cardText}>{text}</div>
                <Toggle
                    checked={isActive}
                    onChange={_handleChange}
                />
            </div>
        </Card>
    );
};
