import dayjs from "dayjs";
import './overview-shift.scss';

interface OverviewShiftProps {
    shift: any
}

export function OverviewShift(props: OverviewShiftProps) {
    return (
        <div className="OverviewShift">
            <div className="header">
                <div className="times">
                    <span>{dayjs(props.shift.start).format('HH:mm')}</span>
                    <span>{dayjs(props.shift.end).format('HH:mm')}</span>
                </div>
                <div className="details" style={{ "--shift-color": "#" + props.shift.activityType.backColor } as React.CSSProperties}>
                    <span className="name">
                        {props.shift.activityType.shortName}
                    </span>
                    <span className="meta">
                        {props.shift.activityType.displayName}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default OverviewShift