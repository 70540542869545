import { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as Theme from './services/theme';
import * as Api from './services/api';
import Schedule from './components/views/schedule/schedule';
import { useQuery } from './hooks/use-query'
import dayjs from 'dayjs';
import { FullScreenLoader } from './components/shared/full-screen-loader/full-screen-loader';
import { useAuth } from './auth/use-auth';
import './App.scss';
import TeamSchedule from './components/views/team-schedule/team-schedule';
import { ScheduleDetails } from './components/views/schedule-details/schedule-details';
import { useTranslation } from 'react-i18next';
import { Errors } from './components/views/errors/errors';
import Activities from './components/views/activities/activities';

const App = () => {
  const { token, error } = useAuth();
  const query = useQuery()
  const [magazine, setMagazine] = useState<any | null>(null);
  const [magazineError, setMagazineError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { i18n } = useTranslation();
  const magazineToken = query.get("token")
  const magazineId = query.get("magazine");

  useEffect(() => {
    dayjs.locale(i18n.language.split('-')[0])
  }, [i18n])

  useEffect(() => {
    async function loadMagazine() {
      if (magazineToken && magazineId) {
        try {
          const magazine = await Api.getMagazine(magazineToken, magazineId);
          setMagazine(magazine);
          setMagazineError(false);
          setErrorMessage("");
        } catch (err) {
          console.error(err);
          setMagazineError(true);
          setErrorMessage("Invalid token or magazine id.");
        }
      }
    }
    loadMagazine();
  }, [magazineToken, magazineId]);

  useEffect(() => {
    const themeId = magazine?.config?.client?.themeId || "ortec";
    if (themeId === "ortec") return
    const currentTheme = Theme.get(themeId);
    Theme.loadVariables(currentTheme);
  }, [magazine]);

  if (!token && !error) return <FullScreenLoader />;

  if (!magazineError) {
    return <>
      <div className="app">
        <Switch>
          <Route path="/errors">
            <Errors />
          </Route>
          <Route exact path="/schedule/:date/details">
            <ScheduleDetails />
          </Route>
          <Route exact path="/schedule/:date?">
            <Schedule />
          </Route>
          <Route exact path="/team-schedule/:date?">
            <TeamSchedule />
          </Route>
          <Route exact path="/activities">
            <Activities />
          </Route>
          <Route exact path="/login">
            <FullScreenLoader />
          </Route>
          <Route path="/">
            <Redirect to={`/schedule/?${query.toString()}`}></Redirect>
          </Route>

        </Switch>
      </div>



    </>;
  } else {
    return <div>{errorMessage}</div>
  }
}

export default App;
