import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v2',
        fallbackLng: 'en',
        supportedLngs: [
            'en',
            'nl',
            'nl-NL'
        ],
        debug: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: true
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + new Date().getTime()
        }
    });

export default i18n;