import { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
    ActivityInterface,
    Preference,
} from "../../../interfaces/activity.interface";
import {
    OMRPOrganizationUnit,
    OMRPResource,
    OrgUnitMembership,
    ResourceFilter,
    ResourceInterface,
    ResourceProperty,
    ResourcePropertyWithValues,
} from "../../../interfaces/resource.interface";
import { Accordion, AccordionSummary } from "@material-ui/core";
import { ResourceTable } from "../resource-table/resource-table";
import { Icon } from "@o4c/plugin-components";
import _ from "lodash";
import { DayPart, TeamScheduleDate } from "../../../interfaces/day.interface";

import styles from "./resource-type-accordion.module.scss";
import { useTranslation } from "react-i18next";
import { OMRP_DISPLAY_NAME_PROPERTY_ID, OMRP_ORG_UNIT_PROPERTY_ID } from "../../views/team-schedule/team-schedule";

interface IResourceAccordionProps {
    resourceType: Preference;
    resourcesWithActivities: ResourceInterface[];
    resources: OMRPResource[];
    organizationUnits: OMRPOrganizationUnit[];
    dayParts: DayPart[];
    dates: TeamScheduleDate[];
    isFiltersDialogOpen: boolean;
    filters?: ResourceFilter;
    handleClickTile: (activity: ActivityInterface) => void;
}

export const ResourceTypeAccordion: FunctionComponent<
    IResourceAccordionProps
> = (props: IResourceAccordionProps) => {
    const {
        resourceType,
        resources,
        organizationUnits,
        resourcesWithActivities,
        handleClickTile,
        dayParts,
        dates,
        isFiltersDialogOpen,
        filters,
    } = props;

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [hasFilters, filterValues] = useMemo((): [boolean, Preference[]] => {
        if (!filters) {
            return [false, []];
        }

        const filterValues = filters?.actives.filter(
            (f: Preference) => f.isActive
        );
        return [!_.isEmpty(filterValues), filterValues];
    }, [filters]);

    const filteredResources = useMemo((): ResourceInterface[] => {
        if (!hasFilters || !filters) {
            return resourcesWithActivities;
        }

        if (filters.selectedProperty.resourcePropertyId === OMRP_DISPLAY_NAME_PROPERTY_ID) {
            return filterOnDisplayName(
                resourcesWithActivities,
                resources,
                filterValues
            );
        }

        if (filters.selectedProperty.resourcePropertyId === OMRP_ORG_UNIT_PROPERTY_ID) {
            return filterOnOrganizationUnit(
                resourcesWithActivities,
                resources,
                organizationUnits,
                filterValues
            );
        }

        return filterOnResourceProperty(
            resourcesWithActivities,
            resources,
            filterValues,
            filters
        );
    }, [hasFilters, filters, resourcesWithActivities, resources, filterValues, organizationUnits]);

    const hasResources = useMemo((): boolean => {
        return !_.isEmpty(filteredResources);
    }, [filteredResources]);

    useEffect(() => {
        if (isFiltersDialogOpen) {
            setIsOpen(false);
        }
    }, [isFiltersDialogOpen]);

    //Creating this to provide an empty table for styling purposes
    //might not be the best solution
    const emptyResource = {
        resource: "",
        resourceId: -1,
        resourceTypeIds: [],
        dayParts: dayParts.map((d) => ({
            ...d,
            days: dates,
        })),
    };

    return (
        <>
            <tr className="accordion-row">
                <Accordion
                    expanded={
                        isFiltersDialogOpen || !hasResources ? false : isOpen
                    }
                    square={true}
                >
                    <AccordionSummary
                        aria-controls={`${resourceType.id}-content`}
                        id={`${resourceType.id}-header`}
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        disabled={!hasResources}
                    >
                        <div className={styles.accordionTitle}>
                            <div className={styles.titleLabel}>
                                <div className={styles.expandIcon}>
                                    {isOpen ? (
                                        <Icon name="arrow-up" color="primary" />
                                    ) : (
                                        <Icon
                                            name="arrow-down"
                                            color="primary"
                                        />
                                    )}
                                </div>
                                <div className={styles.label}>
                                    {resourceType.id}
                                </div>
                            </div>
                            <div className={styles.filterButton}>
                                <div className={styles.filter}>
                                    {hasFilters && (
                                        <div className={styles.filterSelected}>
                                            {`${t("resources.filtered")}`}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </AccordionSummary>
                    {hasResources ? (
                        filteredResources.map(
                            (resource: ResourceInterface, index: number) => (
                                <ResourceTable
                                    key={"resourceTable" + index}
                                    resource={resource}
                                    handleClickTile={handleClickTile}
                                />
                            )
                        )
                    ) : (
                        <ResourceTable
                            resource={emptyResource}
                            handleClickTile={handleClickTile}
                        />
                    )}
                </Accordion>
            </tr>
        </>
    );
};

//--------------Helper functions-------------------------------------------------------------

const getValuesFromResourceProperties = (
    property: ResourcePropertyWithValues,
    resourceProperties: ResourceProperty[]
) => {
    const valuesForProperty = _.filter(resourceProperties, (rp) => {
        return rp.resourcePropertyId === property.resourcePropertyId;
    });

    return _.map(valuesForProperty, (rp) => rp.value);
};

const filterOnDisplayName = (
    resourcesWithActivities: ResourceInterface[],
    resources: OMRPResource[],
    filterValues: Preference[]
) => {
    return resourcesWithActivities.filter((r) => {
        const activeValues = filterValues.map((p) => p.id);
        const resource = resources.find((_r) => r.resourceId === _r.id);

        if (!resource) {
            return false;
        }

        return activeValues.includes(resource?.displayName) ?? false;
    });
};

const getValuesFromOrganizationUnit = (
    organizationUnitMemberships: OrgUnitMembership[],
    organizationUnits: OMRPOrganizationUnit[],
) => {
    let values: string[] = [];
    organizationUnitMemberships.forEach(oum => {
        const organizationUnit = organizationUnits.find(ou => ou.id === oum.organizationUnitId)
        if(organizationUnit) {
            values.push(organizationUnit.displayName);
        }
    })

    return values
};

const filterOnOrganizationUnit = (
    resourcesWithActivities: ResourceInterface[],
    resources: OMRPResource[],
    organizationUnits: OMRPOrganizationUnit[],
    filterValues: Preference[],
) => {
    return resourcesWithActivities.filter((r) => {
        const resourceObject = resources.find((ro) => ro.id === r.resourceId);
        if (!resourceObject) {
            return false;
        }

        const propertyValues = getValuesFromOrganizationUnit(
            resourceObject.organizationUnitMemberships,
            organizationUnits
        );

        const activeValues = filterValues.map((p) => p.id);

        return !_.isEmpty(
            propertyValues.filter((v) => activeValues.includes(v))
        );
    });
};

const filterOnResourceProperty = (
    resourcesWithActivities: ResourceInterface[],
    resources: OMRPResource[],
    filterValues: Preference[],
    filters: ResourceFilter
) => {
    return resourcesWithActivities.filter((r) => {
        const resourceObject = resources.find((ro) => ro.id === r.resourceId);
        if (!resourceObject) {
            return false;
        }

        const propertyValues = getValuesFromResourceProperties(
            filters.selectedProperty,
            resourceObject.resourceProperties
        );

        const activeValues = filterValues.map((p) => p.id);

        return !_.isEmpty(
            propertyValues.filter((v) => activeValues.includes(v))
        );
    });
};
