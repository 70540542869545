import { FunctionComponent } from "react";

import { getClass } from "../../../services/schedule";
import { DayPartActivities } from "./day-part-activities/day-part-activities";
import { DayPart, TeamScheduleDate } from "../../../interfaces/day.interface";
import { ActivityInterface } from "../../../interfaces/activity.interface";

interface IDayPartRowProps {
    index: number;
    dayPartsSize: number;
    dayPart: DayPart;
    handleClickTile: (activity: ActivityInterface) => void;
}

export const DayPartRow: FunctionComponent<IDayPartRowProps> = (
    props: IDayPartRowProps
) => {
    const { index, dayPartsSize, dayPart, handleClickTile } = props;

    return (
        <tr className={`daypart ${getClass(index, dayPartsSize)}`}>
            <DayPartRowHead
                label={dayPart.name.substring(0, 2).toUpperCase()}
            />
            {dayPart.days.map((day: TeamScheduleDate, innerIndex: number) => (
                <DayPartActivities day={day} handleClickTile={handleClickTile} key={"daypartActivities" + innerIndex} />
            ))}
        </tr>
    );
};

const DayPartRowHead = (props: { label: string }) => {
    return <td className="head">{props.label}</td>;
};
