import { FunctionComponent } from 'react';
import { Source } from './activities-utils';
import note_indicator from "../../../assets/note_indicator.svg"
import cardStyle from "./source-card.module.scss"

interface ISourceCardProps {
    handleClickCard: (cardRootId: number) => void;
    sources: Source[],
}

const SourceCard: FunctionComponent<ISourceCardProps> = ({ handleClickCard, sources }) => {
    return (<>{
        sources.length ? sources.map((source, sourceIndex: number) => (
            <div className={cardStyle.sourceCard} key={"source" + sourceIndex} onClick={() => handleClickCard(Number(source.rootId))}>
                {source.hasMemo && <img className={cardStyle.noteIndicator} src={note_indicator} alt="note" />}
                {source && source.lines?.length && source.lines.map((s: string, si: number) => <div className={cardStyle.singleSource} key={s + si}>{s}</div>)}
            </div>
        )) : null
    }</>);
};

export default SourceCard;