import { useTranslation } from "react-i18next"

const NoShiftCard = () => {
    const { t } = useTranslation()
    return <div className="o4c card muted">
        <div className="main">
            {t('schedule.noShift')}
        </div>
    </div>
}

export default NoShiftCard
