import dayjs, { Dayjs } from "dayjs";
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import { useAPI } from "../../../../hooks/use-api";
import { Calendar, CalendarViewMode } from "../../../shared/calendar/calendar";
import { convertShiftsToCalendarEvents } from "../../../../services/schedule";

interface IShiftDetailCalendarProps {
  date: Dayjs;
}

export function ShiftDetailCalendar(props: IShiftDetailCalendarProps) {
  const history = useHistory();
  let { fetcher } = useAPI();
  const startDate = props.date.startOf('month').format('YYYY-MM-DD');
  const endDate = props.date.endOf('month').format('YYYY-MM-DD');

  let { data, error } = useSWR<any>(`/api/schedules/month?startDate=${startDate}&endDate=${endDate}`, fetcher)


  if (error) return <div>Failed to load!</div>;

  const events: any = convertShiftsToCalendarEvents(data?.activities || []);

  const onDatePressed = (date: Date) => {
    history.push(`/schedule/${dayjs(date).format('YYYY-MM-DD')}/details`);
  }

  return (
    <Calendar currentDate={props.date} onDatePressed={onDatePressed} events={events} viewMode={CalendarViewMode.Slider} />
  )
}