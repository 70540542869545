import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ProvideAuth } from './auth/provide-auth';
// import { store } from './app/store';
// import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './services/i18n'
import './index.scss';
import { SWRConfig } from 'swr';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import 'dayjs/locale/nl'

dayjs.extend(AdvancedFormat)
dayjs.extend(isoWeek)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
    <Suspense fallback={<></>}>
      <Router>
        <ProvideAuth>
            <SWRConfig value={{
              revalidateOnFocus: false,
              shouldRetryOnError: false
            }}>
              <App />
            </SWRConfig>
        </ProvideAuth>
      </Router>
    </Suspense>
    {/* </Provider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
