import { Button } from "@o4c/plugin-components";
import styles from "./empty-activities-tab.module.scss";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IEmptyActivitiesTabProps {
    openPreferences: (isPreferencesOpen: boolean) => void;
    tab: "activities" | "resources";
    emptyLabel: string;
}

export const EmptyActivitiesTab: FunctionComponent<IEmptyActivitiesTabProps> = (
    props: IEmptyActivitiesTabProps
) => {
    const { openPreferences, emptyLabel, tab } = props;
    const { t } = useTranslation();
    return (
        <div className={styles.contentWrapper}>
            <div className={styles.emptyActivitiesContent}>
                <div className={styles.emptyActivitiesText}>
                    {emptyLabel}
                </div>
                <Button color="primary" onClick={openPreferences}>
                    {t(`${tab}.openPreferences`)}
                </Button>
            </div>
        </div>
    );
};
