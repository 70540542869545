import { Dayjs } from "dayjs";
import { MemoRoot } from "../../../shared/memo-root/memo-root";
import OverviewShift from "../../../shared/overview-shift/overview-shift";
import ShiftResources from "../shift-resources/shift-resources";
import "./shift-detail-card.scss";
import { useAPI } from "../../../../hooks/use-api";
import { useMemo } from "react";
import useSWR from "swr";
import { createDetailsObject } from "../../../../services/schedule";

interface IShiftDetailCardProps {
    date: Dayjs;
    shift: any;
}

export function ShiftDetailCard(props: IShiftDetailCardProps) {
    let { fetcher } = useAPI();
    const { data: activityData, isLoading } = useSWR(
        `/api/schedules/details?activity=${props.shift.id}`,
        fetcher
    );

    const detailsObject = useMemo(() => {
        if (!activityData) {
            return props.shift;
        }

        return createDetailsObject(activityData);
    }, [props.shift, activityData]);

    return (
        <>
            {!isLoading && (
                <div className="o4c card">
                    <div className="main">
                        <ShiftDetailCardContent shift={detailsObject} />
                    </div>
                </div>
            )}
        </>
    );
}

interface IShiftDetailCardContentProps {
    shift: any;
}
export function ShiftDetailCardContent(props: IShiftDetailCardContentProps) {
    return (
        <>
            <div className="overview">
                <OverviewShift shift={props.shift} />
            </div>
            {props.shift.memo && <MemoRoot>{props.shift.memo}</MemoRoot>}
            {props.shift.activities?.length ? (
                <ShiftResources shift={props.shift} />
            ) : null}
        </>
    );
}
