import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../auth/use-auth';
import './errors.scss';

export function Errors() {
    const { t } = useTranslation();
    const { error, errorDescription } = useAuth();
    const [showDetails, setShowDetails] = useState(false);

    return <div className="errors-container">
        <div className="o4c card errors">
            <div className="header">
                <div><i className="icon-alert leading" /> {t('errors.system.header')}</div>
            </div>
            <div className="main">
                <p><strong>{t('errors.system.title')}</strong></p>
                <p>{t('errors.system.text')}</p>
                {!showDetails ? <button className="o4c button outline" onClick={() => setShowDetails(true)}>{t('errors.system.showDetails')}</button> : null}
                {showDetails ? <div className="error-details">
                    <strong>{error}:</strong> {errorDescription}
                </div> : null}
            </div>
        </div>
    </div>
}